import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Table,
  Button,
  Image,
  Modal,
  Dropdown,
  FormSelect,
} from "react-bootstrap";
import "./Dashboard.scss";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";

import Header from "./Header";
import TableJs from "./Table";
import Book from "../../assets/img/book.png";
import Due from "../../assets/img/due-date.png";
import Percentage from "../../assets/img/discount.png";
import Income from "../../assets/img/salary.png";
import loaderImage from "../../assets/img/logo.png";
import Lottie from 'react-lottie';
import SadEmoji from '../../assets/lottie/sad_emoji.json'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useTheme } from './ThemeContext';

// format the number so that you can get shorted versions of it
const formatNumber = (number) => {
  if (number >= 1000) {
    const roundedNumber = Math.floor(number / 1000);
    return `${roundedNumber}k`;
  }
  return number;
};

function Dashboard() {
  const [showSidebar, setShowSidebar] = useState(true);
  const { theme, toggleTheme } = useTheme();

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // loading status
  const [isLoading, setIsLoading] = useState(true);
  // apartments of the landlord
  const [landlordApartment, setLandlordApartment] = useState([]);
  const [incomeCount, setIncomeCount] = useState({});
  const [defaulters, setDefaulters] = useState("");
  const [defaultersApartment, setDefaultersApartment] = useState({});
  const [monthIncome, setMonthIncome] = useState(0);
  const [rentIncome, setRentIncome] = useState(0);
  const [utilityIncome, setUtilityIncome] = useState(0);
  const [landlordIncomeDue, setLandlordIncomeDue] = useState(0);
  const [landlordPreviousOccupancy, setLandlordPreviousOccupancy] = useState(0);
  const [landlordCurrentOccupancy, setLandlordCurrentOccupancy] = useState(0);
  const [landlordPercentagePaid, setLandlordPercentagePaid] = useState(0);
  const [selectedApartmentImage] = useState({});
  // for calculations purposes
  const [setMonthIncomeOldSchool] = useState(0);
  const [setPreviousMonthIncomeOldSchool] = useState(0);
  // modal to be used in filtering on the month
  const [addModal, setAddModal] = useState(false);
  // rent apartment table metrics
  const [rentPaidApartment, setRentPaidApartment] = useState("");
  const [rentPercentageApartment, setRentPercentageApartment] = useState("");

  const [subscriptionAmount, setSubscriptionAmount] = useState("");

  const handleMonthClose = () => {
    setAddModal(false);
  };
  const handleCloseModalReload = () => {
    setAddModal(false);
    window.location.reload();
  };
  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString("default", { month: "long" });
  });
  // Get the current month automatically
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];
  // Get the previous month automatically
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selecteYear, setSelectedYear] = useState("");
  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;
  const years = Array.from({ length: 21 }, (_, i) => startYear - i);
  // display 12 churches per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);
  // modals for the year display
  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };
  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);
  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("");
  const [yearFilterActive] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    localStorage.setItem("month", month);
    setShowModal(false);
    window.location.reload();
  };

  // set the year filtered in the local storage
  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
    localStorage.setItem("yearRentIncomeDashboard", year);
    setShowModal(false);
    window.location.reload();
  };
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;
  const [userLogedIn, setUserLogedIn] = useState([]);
  const [showModalInactive, setShowModalInactive] = useState(false);

  // from the localstorage get the logged in user
  useEffect(() => {
    setIsLoading(true);
    // get the user details
    serverUrl
      .get(`/users/users-details/?id=${userId}`, config)
      .then((res) => {
        setUserLogedIn(res.data.results[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    // get the landlord details that are linked to the user
    serverUrl
      .get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        // get the total income of the properties in the previous month
        let selectedMonthModalPrevious = localStorage.getItem("month");
        setMonthFilterActive(selectedMonthModalPrevious);
        let previousMonth;
        if (selectedMonthModalPrevious) {
          const selectedMonthDate = new Date(selectedMonthModalPrevious);
          // Check if the selected month is a valid date
          if (!isNaN(selectedMonthDate)) {
            selectedMonthDate.setMonth(selectedMonthDate.getMonth() - 1);
            previousMonth = selectedMonthDate.toLocaleString("default", {
              month: "long",
            });
          } else {
            // Invalid date, fallback to the previous month of the current date
            const currentMonthDate = new Date();
            currentMonthDate.setMonth(currentMonthDate.getMonth() - 1);
            previousMonth = currentMonthDate.toLocaleString("default", {
              month: "long",
            });
          }
        } else {
          // No selected month, fallback to the previous month of the current date
          const currentMonthDate = new Date();
          currentMonthDate.setMonth(currentMonthDate.getMonth() - 1);
          previousMonth = currentMonthDate.toLocaleString("default", {
            month: "long",
          });
        }
        serverUrl
          .get(
            `/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${encodeURIComponent(
              previousMonth
            )}`,
            config
          )
          .then((res) => {
            setPreviousMonthIncomeOldSchool(res.data.total_rent_income);
          })
          .catch((error) => {
            console.log(error);
          });
        // set to get the month filtered if not then display the current month
        let selectedMonthModal = localStorage.getItem("month");
        if (!selectedMonthModal) {
          selectedMonthModal = currentMonth;
        }
        // get the total income of the properties in the current month
        serverUrl
          .get(
            `/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${selectedMonthModal}&year=${currentYear}`,
            config
          )
          .then((res) => {
            setMonthIncomeOldSchool(res.data.total_rent_income);
            const income = res.data.total_rent_income;
            const rent_total = res.data.rent_total;
            const utilities_total = res.data.utilites_total;
            const utilityTotal = formatNumber(utilities_total);
            const rentTotal = formatNumber(rent_total);
            // formated Income
            setMonthIncome(income);
            setRentIncome(rentTotal);
            setUtilityIncome(utilityTotal);
          })
          .catch((error) => {
            console.log(error);
          });

        // get the total number of defaulters in the properties
        serverUrl
          .get(
            `/landlord/get_defaulters_list/?landlord_id=${landlordIds}`,
            config
          )
          .then((res) => {
            setDefaulters(res.data.defaulters);
          })
          .catch((error) => {
            console.log(error);
          });
        // get the occupancy percrentage
        serverUrl
          .get(
            `/landlord/get_rent_summary/?landlord_id=${landlordIds}&month=${selectedMonthModal}&year=${currentYear}`,
            config
          )
          .then((res) => {
            setLandlordPreviousOccupancy(
              res.data.previous_occupancy_percentage
            );
            setLandlordCurrentOccupancy(res.data.occupancy_percentage);
            // formated rentDue
            const formattedRentDue = res.data.rent_due;
            setLandlordIncomeDue(formattedRentDue);
            setLandlordPercentagePaid(res.data.percentage_paid);
          })
          .catch((error) => {
            console.log(error);
          });
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          return serverUrl
            .get(
              `/landlord/landlord-apartment_name/?landlord_id=${landlordId}`,
              config
            )
            .then((res) => res.data.results)
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);
            // get the rent paid and occupancy amount per apartment
            const apartmentPromises = apartments.map((apartment) => {
              return serverUrl
                .get(
                  `/landlord/get_rent_summary/?apartment_id=${apartment.id}&month=${selectedMonthModal}&year=${currentYear}`,
                  config
                )
                .then((res) => ({
                  apartmentId: apartment.id,
                  dataSummary: res.data,
                }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, dataSummary: res.data };
                });
            });
            // set count of the number of houses that are in that apartment
            Promise.all(apartmentPromises)
              .then((results) => {
                // object that gets the number of apartments
                const apartmentRentPaid = {};
                const apartmentOccupancyPercentage = {};
                const apartmentRentPaidExpected = {};
                results.forEach(({ apartmentId, dataSummary }) => {
                  apartmentRentPaid[apartmentId] = dataSummary.rent_paid;
                  apartmentOccupancyPercentage[apartmentId] =
                    dataSummary.occupancy_percentage;
                  apartmentRentPaidExpected[apartmentId] =
                    dataSummary.total_rent;
                });
                setRentPaidApartment(apartmentRentPaid);
                setRentPercentageApartment(apartmentOccupancyPercentage);
                setIncomeCount(apartmentRentPaidExpected);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
              });



            //get the details based on the wallet data
            const walletPromises = apartments.map((apartment) => {
              return serverUrl
                .get(`/landlord/subscriptions-invoice/?apartment=${apartment.id}`, config)
                .then((res) => {
                  console.log(res.data.results)
                  const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
                  if (res.data.results.length > 0) {
                    setShowModalInactive(true)

                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            });


            // the subscription amount
            const unitAmountSubscriptionPromises = apartments.map(
              (apartment) => {
                return serverUrl
                  .get(
                    `/landlord/subscription-amount-units/?apartment_id=${apartment.id}`,
                    config
                  )
                  .then((res) => ({
                    apartmentId: apartment.id,
                    data: res.data.results[0],
                    units: res.data.units,
                  }))
                  .catch((error) => {
                    console.log(error);
                    return { apartmentId: apartment.id };
                    setIsLoading(false);
                  });
              }
            );
            // set count of the number of houses that are occupied in that apartment
            Promise.all(unitAmountSubscriptionPromises)
              .then((results) => {
                // object that gets the number of apartments
                const houseOccupiedObj = {};
                results.forEach(({ apartmentId, data }) => {
                  houseOccupiedObj[apartmentId] = data;
                });

                setSubscriptionAmount(houseOccupiedObj);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });

            // get the subscription PLAN
            const subscriptionPromises = apartments.map((apartment) => {
              return serverUrl
                .get(
                  `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                  config
                )
                .then((res) => ({
                  apartmentId: apartment.id,
                  subscription: res.data.subscription,
                }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id };
                });
            });

            // get the subscription status
            const subscriptionStatusPromises = apartments.map((apartment) => {
              return serverUrl
                .get(
                  `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                  config
                )
                .then((res) => ({
                  apartmentId: apartment.id,
                  subscription: res.data.results,
                }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id };
                });
            });

            // set the response of the subscriptions
            Promise.all(subscriptionPromises)
              .then((results) => {
                // object that gets the number of apartments
                const subscriptionObj = {};
                results.forEach(({ apartmentId, subscription }) => {
                  subscriptionObj[apartmentId] = subscription;
                });
                setSubscriptionStatus(subscriptionObj);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });

            // set the response of the subscriptions plan
            Promise.all(subscriptionStatusPromises)
              .then((results) => {
                // object that gets the number of apartments
                const subscriptionObj = {};
                results.forEach(({ apartmentId, subscription }) => {
                  subscriptionObj[apartmentId] = subscription;
                });
                setSubscription(subscriptionObj);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });

            // get the income monthly
            const houseIncomePromises = apartments.map((apartment) => {
              return serverUrl
                .get(
                  `/tenant/expected-rental-income-by-month/?apartment_id=${apartment.id}`,
                  config
                )
                .then((res) => ({
                  apartmentId: apartment.id,
                  count: res.data.total_rental_income,
                }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, count: 0 };
                });
            });
            // set count of the number of houses that are occupied in that apartment
            Promise.all(houseIncomePromises)
              .then((results) => {
                // object that gets the number of apartments
                const houseIncomeObj = {};
                results.forEach(({ apartmentId, count }) => {
                  houseIncomeObj[apartmentId] = count;
                });
                // setIncomeCount(houseIncomeObj);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });
            // get the defaulters in the each apartment
            let totalAmount = 0;
            const defaulterApartment = {};
            apartments
              .map((defaultersApartment) => {
                serverUrl
                  .get(
                    `/landlord/get_defaulters_list/?apartment_id=${defaultersApartment.id}`
                  )
                  .then((res) => {
                    defaulterApartment[defaultersApartment.id] =
                      res.data.defaulters;
                    setDefaultersApartment(defaulterApartment);
                    // Calculate the total amount
                    const defaulterAmounts = res.data.defaulters;
                    const apartmentTotalAmount = defaulterAmounts;
                    totalAmount += apartmentTotalAmount;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // randomise for each apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState("");
  useEffect(() => {
    const getRandomImage = () => {
      const usedImages = Object.values(backgroundImageApartment);
      const availableImages = Array.from(
        { length: 10 },
        (_, index) => index + 1
      );
      const remainingImages = availableImages.filter(
        (image) => !usedImages.includes(image)
      );
      const randomNumber =
        remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      setIsLoading(true);

      const updatedImages = { ...backgroundImageApartment };

      // Loop through each house and update the image if not set
      for (const unitDetail of landlordApartment) {
        const apartmentId = unitDetail.id;
        if (!updatedImages[apartmentId]) {
          updatedImages[apartmentId] = getRandomImage();
        }
      }
      setBackgroundImageApartment(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [landlordApartment]);
  // Perform the subtraction and determine the result for the income monthly
  let result = Math.round(landlordPercentagePaid);
  // Check if the result is infinity and assign 0 if true
  if (!isFinite(result)) {
    result = 0;
  }
  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClass = "";
  let sign = "";
  if (result > 0) {
    textColorClass = "text-success";
    sign = "+";
  } else if (result < 0) {
    textColorClass = "text-danger";
    sign = "-";
  } else if (result === 0) {
    textColorClass = "text-success";
  }
  // Perform the subtraction and determine the result for the occupancy in each month
  let resultOccupancy = landlordCurrentOccupancy - landlordPreviousOccupancy;
  // Check if the result is infinity and assign 0 if true
  if (!isFinite(resultOccupancy)) {
    resultOccupancy = 0;
  }
  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClassOccupancy = "";
  let signOccupancy = "";
  if (resultOccupancy > 0) {
    textColorClassOccupancy = "text-success";
    signOccupancy = "+";
  } else if (resultOccupancy < 0) {
    textColorClassOccupancy = "text-danger";
    signOccupancy = "-";
  } else if (resultOccupancy === 0) {
    textColorClassOccupancy = "text-success";
  }

  // send the reminders from the table that is for every apartment
  function handleSendReminder(apartmentId) {
    try {
      serverUrl
        .get(`/landlord/send_payment_reminder/?apartment_id=${apartmentId}`)
        .then((res) => {
          toast.success("Reminder Sent Successfully!");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) { }
  }


  // send reminder for the landlord
  const [remindersModal, setRemindersModal] = useState(false);
  const [reminderValue, setReminderValue] = useState(null);

  // modal for filtering on the month
  const handleReminderModalOpen = (reminderType) => {
    setRemindersModal(true);
    setReminderValue(reminderType)
  };

  const handleReminderModalClose = () => {
    setRemindersModal(false);
  };
  const handleReminderModalReload = () => {
    setRemindersModal(false);
    window.location.reload();
  };

  const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [customSubject, setCustomSubject] = useState("Custom Message");
  const [customMessage, setCustomMessage] = useState("");
  const isTemplatesEmpty = messageTemplates.length === 0;

  const handleAddTemplate = () => {
    setSelectedOptionReminder("");
    setAddTemplate(true);
  };

  // get the templates
  useEffect(() => {
    fetchMessageTemplates();
  }, [userId]);

  const fetchMessageTemplates = async () => {
    try {
      const response = await serverUrl.get(`/communication/message-templates/?created_by_message_template=${userId}`, config
      );
      setMessageTemplates(response.data.results);
    } catch (error) {
      console.error("Error fetching message templates:", error);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOptionReminder(option);
    const selectedTemplate = messageTemplates.find(
      (template) => template.title.toLowerCase().trim() === option.title.toLowerCase().trim()
    );
    if (selectedTemplate) {
      setCustomSubject(selectedTemplate.title);
      setCustomMessage(selectedTemplate.text);
    }
    setAddTemplate(false);
  };
  const [editableSubject, setEditableSubject] = useState(true);
  const [editableMessage, setEditableMessage] = useState(true);

  // send the reminder for all the apartments that the landlord
  const handleSendRemindersGeneral = () => {
    try {
      const accessToken = localStorage.getItem("authTokens");
      // get the access token
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };
      // decoding the token so that i can get the user id
      const decodedToken = jwt_decode(accessToken);
      const userId = decodedToken.user_id;

      // get the landlord details that are linked to the user
      serverUrl
        .get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
          // get the landlord Id
          const landlordIds = res.data.results.map((landlord) => landlord.id);

          const data = {
            message: customMessage,
            subject: customSubject,
          };
          if (addTemplate) {
            const dataTemplate = {
              title: customSubject,
              text: customMessage,
              created_by_message_template: userId,
              updated_by_message_template: userId
            }
            // post the template
            serverUrl.post(`/communication/message-templates/`, dataTemplate, config)
              .then((res) => {
                toast.success("Reminder sent succesfully!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })

          }
          if (reminderValue === 'general') {
            serverUrl.post(`/landlord/send_payment_reminder/?landlord_id=${landlordIds}`, data, config)
              .then((res) => {
                toast.success("Reminder sent succesfully to all your tenants!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })
          }
          else {
            serverUrl.post(`/landlord/send_payment_reminder/?apartment_id=${reminderValue}`, data, config)
              .then((res) => {
                toast.success("Reminder sent succesfully to all the tenants in the selected property!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })
          }



        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) { }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const totalPages = Math.ceil(landlordApartment.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex =
    currentPage === totalPages - 1
      ? landlordApartment.length
      : startIndex + itemsPerPage;
  const displayedItems = landlordApartment.slice(startIndex, endIndex);
  // RESeting the filter
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("month");
    toast.success("Successfully Monthly Filter On Metrics!");
    window.location.reload();
  };

  // subscription modals
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionModalInvoice, setSubscriptionModalInvoice] =
    useState(false);

  const [subscriptionId, setSubscriptionId] = useState(false);
  const [subscriptionPay, setSubscriptionPay] = useState(false);

  const [selectedSubscriptionAmount, setSelectedSubscriptionAmount] =
    useState(null);
  const [selectedSubscriptionAmountInt, setSelectedSubscriptionAmountInt] =
    useState(null);

  const [selectedSubscriptionPeriod, setSelectedSubscriptionPeriod] =
    useState(null);
  const [selectedSubscriptionDiscount, setSelectedSubscriptionDiscount] =
    useState(null);

  // modal for filtering on the subscription entered
  const handleSubscriptionSelect = (durationType, discount) => {
    if (durationType === "semi_annually") {
      const words = durationType.split('_');
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      const formattedTerm = capitalizedWords.join(' ');

      setSelectedSubscriptionPeriod(formattedTerm);
    }
    else {
      setSelectedSubscriptionPeriod(durationType);

    }
    setSelectedSubscriptionDiscount(discount);
    setSelectedSubscriptionAmount(
      subscriptionAmount[subscriptionId][durationType].toLocaleString()
    );
    setSelectedSubscriptionAmountInt(
      subscriptionAmount[subscriptionId][durationType]
    );
  };
  const handleOpenSubscription = (apartmentId) => {
    setSubscriptionModal(true);
    setSubscriptionId(apartmentId);
  };
  const handleCloseSubscription = () => {
    setSubscriptionModal(false);
  };
  const handleCloseSubscriptionPay = () => {
    setSubscriptionPay(false);
  };
  const [invoices, setInvoices] = useState([]);
  const [invoicesBalance, setInvoicesBalance] = useState('');

  const [selectedApartmentIdInvoice, setSelectedApartmentIdInvoice] =
    useState("");

  const handleOpenSubscriptionInvoice = (apartmentId) => {
    setSubscriptionModalInvoice(true);
    setSelectedApartmentIdInvoice(apartmentId);
    // display the invoices for that apartment
    serverUrl
      .get(`/landlord/subscriptions-invoice/?apartment=${apartmentId}`, config)
      .then((res) => {
        const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
        const totalUnpaidAmount = unpaidInvoices.reduce((total, invoice) => total + parseFloat(invoice.amount), 0);

        setInvoicesBalance(totalUnpaidAmount)
        setInvoices(unpaidInvoices);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseSubscriptionInvoice = () => {
    setSubscriptionModalInvoice(false);
  };

  function handleAddSubscription(e) {
    e.preventDefault();
    // get the subscription wallet
    serverUrl
      .get(`landlord/wallet/?property=${subscriptionId}`, config)
      .then((res) => {
        const wallet = res.data.results.filter(
          (wallet) => wallet.account_type === "subscription"
        );

        const data = {
          property: subscriptionId,
          status: "inactive",
          amount: parseInt(selectedSubscriptionAmountInt),
          duration: selectedSubscriptionPeriod,
          wallet: wallet[0].id,
        };
        // post the subscription data
        serverUrl
          .post(`/landlord/subscriptions/`, data, config)
          .then((res) => {
            const invoiceData = {
              apartment: subscriptionId,
              wallet: wallet[0].id,
              subscription: res.data.id,
              amount: parseInt(selectedSubscriptionAmountInt),
              month: currentMonth.toLocaleLowerCase(),
              year: currentYear,
              status: "unpaid",
              user: userId,
            };
            serverUrl
              .post(`/landlord/subscriptions-invoice/`, invoiceData, config)
              .then((res) => {
                setSubscriptionModal(false);
                toast.success("Succesfully created a subscription plan");
                window.location.reload()
                // setSubscriptionPay(true);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // payment for subscription process
  const [subscriptionPaymentModal, setSubscriptionPaymentModal] =
    useState(false);
  const [walletIdTransaction, setWalletIdTransaction] = useState("");
  const [walletInvoiceIdTransaction, setWalletInvoiceIdTransaction] =
    useState("");
  const [invoiceData, setInvoiceData] = useState({});
  const [apartmentWallets, setApartmentWallets] = useState([]);

  // method payment
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  // mpesa details
  const [selectedMpesaOption, setSelectedMpesaOption] = useState(null);

  const handleSubscriptionPaymentOpen = (invoiceId) => {
    // get the details of the selected invoice
    serverUrl
      .get(`/landlord/subscriptions-invoice/${invoiceId}/`, config)
      .then((res) => {
        setInvoiceData(res.data);
        // get the wallets for the apartment
        serverUrl
          .get(`/landlord/wallet/?property=${res.data.apartment}`, config)
          .then((res) => {
            setApartmentWallets(
              res.data.results.filter(
                (wallet) => wallet.account_type !== "subscription"
              )
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setSubscriptionPaymentModal(true);
    setWalletInvoiceIdTransaction(invoiceId);
    setSubscriptionModalInvoice(false);
    setWalletIdTransaction("0");
  };
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
  const [selectedCurrentWalletBalance, setSelectedCurrentWalletBalance] =
    useState(null);
  const [selectedWalletName, setSelectedWalletName] = useState([]);
  const [subscriptionBalance, setSubscriptionBalance] = useState(null);

  const [enteredAmount, setEnteredAmount] = useState("");

  const handlePaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
    setSelectedWalletName([]);
    handleMpesaOption(null);
  };

  function handleWalletSelect(selectedWalletId) {
    setSelectedWallet(selectedWalletId);
    serverUrl
      .get(
        `/landlord/wallet-account-balance?wallet=${selectedWalletId}`,
        config
      )
      .then((res) => {
        setSelectedWalletBalance(res.data.results[0].wallet_balance);
        const balancePayment =
          res.data.results[0].wallet_balance - parseInt(invoiceData.amount);
        setSelectedCurrentWalletBalance(balancePayment);

        const balance = parseInt(invoiceData.amount) - balancePayment;
      })
      .catch((error) => {
        console.log(error);
      });

    // get the details of the selected wallet
    serverUrl
      .get(`/landlord/wallet/${selectedWalletId}/`, config)
      .then((res) => {
        setSelectedWalletName(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubscriptionPaymentWalletOpen = (invoiceId) => {
    setSubscriptionPaymentModal(true);
    setWalletInvoiceIdTransaction(invoiceId);
    setSubscriptionModalInvoice(false);
  };

  const handleSubscriptionPaymentClose = () => {
    setSubscriptionPaymentModal(false);
    setSelectedPaymentMethod(null);
    setSelectedMpesaOption(null);
  };

  const [phoneNumberPayment, setPhoneNumberPayment] = useState("");

  const handlePhoneNumberChange = (e) => {
    setPhoneNumberPayment(e.target.value);
  };

  const [amountPhone, setAmountPhone] = useState("");
  const mpesSubscriptionBalance = parseInt(invoicesBalance) - parseInt(amountPhone)

  const handleAmountPayment = (e) => {
    setAmountPhone(e.target.value);
  };

  const handleMpesaOption = (method) => {
    setSelectedMpesaOption(method);
  };

  const handlePaySubscription = () => {
    if (selectedPaymentMethod === "wallet") {
      const data = {
        subscription_wallet: parseInt(selectedWallet),
        wallet: invoiceData.wallet,
        property: selectedApartmentIdInvoice,
        wallet_balance: parseInt(selectedWalletBalance) - parseInt(enteredAmount),
        wallet_name: selectedWalletName.name,
        invoice: parseInt(invoiceData.id),
        amount_paid: parseInt(enteredAmount),
      };
      const formData = new FormData();

      // Append each key-value pair to the FormData object
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, String(data[key]));
        }
      }
      // post the data for the payment
      serverUrl
        .post(`/landlord/pay-subscription-from-wallet/`, formData, config)
        .then((res) => {
          // show paid and close the modal
          toast.success("Succesfuly made payment for the subscription");
          setEnteredAmount(null);
          setSelectedWallet(null);
          setSelectedWalletName(null);
          setSelectedPaymentMethod(null);
          setSubscriptionPaymentModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    else {
      let modifiedPhoneNumber = "";

      if (selectedMpesaOption === "my_number") {
        modifiedPhoneNumber = userLogedIn.phone_number;

        if (userLogedIn.phone_number.length === 10 && userLogedIn.phone_number.startsWith("0")) {
          modifiedPhoneNumber = "254" + userLogedIn.phone_number.slice(1);
        }
      } else if (selectedMpesaOption === "other_number") {
        modifiedPhoneNumber = phoneNumberPayment;

        if (phoneNumberPayment.length === 10 && phoneNumberPayment.startsWith("0")) {
          modifiedPhoneNumber = "254" + phoneNumberPayment.slice(1);
        }
      }

      const data = {
        amount: parseInt(amountPhone),
        phone_number: modifiedPhoneNumber,
        current_balance: parseInt(invoiceData.amount) - amountPhone,
        wallet: invoiceData.wallet,
        subscription_plan_id: invoiceData.subscription,
        invoice_id: parseInt(invoiceData.id),
        property_id: invoiceData.apartment

      };
      console.log(data)

    }
  };
  const handleAmountChange = (amount) => {
    setEnteredAmount(amount);
  };
  const handleCloseModalInactive = () => {

    setShowModalInactive(false);
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SadEmoji,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && <SideBar />}

      <div className={`dashboard-${theme}`}>
        {/* inactive subscription modal */}
        <Modal className={`inactiveModal-${theme}`} show={showModalInactive} onHide={handleCloseModalInactive} style={{ marginTop: "7.5rem" }} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{ }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="text-center border-0 position-relative">
              <div className="card-background"></div>
              <div className='card-body-content'>
                <h3 className='text-uppercase'>Your plan is not activated</h3>
                <p className='w-75 text-center mx-auto'>It seems you have uncleared invoices. In order to continue enjoying our services, please take a moment to settle them.</p>
                <div className="d-flex justify-content-center">
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={400}
                  />
                </div>
                <p>We value your continued presence and support. We kindly ask you to activate your plan by settling the outstanding invoices.</p>
                <Button className='w-auto d-flex text-center mb-4 ' style={{ margin: "0 auto" }}>
                  <Link className='text-white text-decoration-none' to='/subscription'>
                    ACTIVATE NOW
                  </Link>
                </Button>
              </div>
            </Card>


          </Modal.Body>
          {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalInactive}>Close</Button>
                    </Modal.Footer> */}
        </Modal>
        <p></p>

        {/* filtred calendar */}
        <div className="filterCalendar d-flex justify-content-between">
          {monthFilterActive ? (
            <>
              <Button
                className="text-white filterButton"
                style={{ marginLeft: "-65rem" }}
                onClick={handleReset}
              >
                Reset Filter
              </Button>
              <p className="text-muted text-uppercase fw-bold">
                Metrics filters for month {monthFilterActive}
              </p>
            </>
          ) : (
            <></>
          )}
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="bi bi-calendar" />
              </span>
            </div>

            <Form.Select
              size="sm"
              className="formCal"
              onChange={handleSelectChange}
            >
              {monthFilterActive ? (
                <>
                  <option>{monthFilterActive}</option>
                  <option>Month</option>

                  <option>Year</option>
                </>
              ) : yearFilterActive ? (
                <>
                  <option>{yearFilterActive}</option>
                  <option>Month</option>

                  <option>Year</option>
                </>
              ) : (
                <>
                  <option>{currentMonth}</option>
                  <option>Month</option>
                  <option>Year</option>
                </>
              )}
            </Form.Select>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header className="bg-primary text-white" closeButton>
                <Modal.Title>Filter By {selectedOption}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedOption === currentMonth ? (
                  <>
                    <p>{currentMonth}</p>
                  </>
                ) : selectedOption === "Month" ? (
                  <>
                    <Form>
                      <Row className="flex-wrap justify-content-between">
                        {months.map((month) => (
                          <Col
                            key={month}
                            xs={6}
                            sm={4}
                            md={4}
                            className="mt-3"
                          >
                            <Button
                              className="btn btn-primary text-white w-75"
                              style={{ marginInline: "0.5rem" }}
                              onClick={() => handleMonthButtonClick(month)}
                            >
                              {month}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </Form>
                  </>
                ) : selectedOption === "Year" ? (
                  <>
                    <Form>
                      <Row className="flex-wrap justify-content-between">
                        {displayedYears.map((year) => (
                          <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                            <Button
                              className="btn btn-primary text-white w-75"
                              style={{ marginInline: "0.5rem" }}
                              onClick={() => handleYearButtonClick(year)}
                            >
                              {year}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                      <div className="mt-3 d-flex justify-content-center">
                        {currentYearPage > 0 && (
                          <Button
                            variant="outline-primary"
                            onClick={handlePrevYearPage}
                          >
                            Previous
                          </Button>
                        )}
                        {currentYearPage < totalPagesYear - 1 && (
                          <Button
                            variant="outline-primary"
                            onClick={handleNextYearPage}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <p>Date</p>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        {/* modal for filtering per month */}
        <Modal show={addModal} onHide={handleMonthClose}>
          <Modal.Header closeButton className="bg-primary text-white">
            <Modal.Title>Filter You Data By Month</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="flex-wrap justify-content-between">
              {months.map((month) => (
                <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                  <Button style={{ marginInline: "0.5rem" }}>{month}</Button>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="text-white"
              onClick={handleCloseModalReload}
            >
              Filter
            </Button>
            <Button variant="light">Cancel</Button>
          </Modal.Footer>
        </Modal>

        {/* the cards displayed after the filtered calendar */}
        <div className="staticCards">
          <Card className="rentDue">
            <Card.Body>
              <Image src={Due}></Image>
              <div className="float-left">
                <span>Rent Due</span>
                <div className="d-flex mt-2">
                  <p>{landlordIncomeDue?.toLocaleString()}</p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className="defaulters">
            <Card.Body>
              <Image src={Book}></Image>
              <div className="float-left">
                <span className="defaultersText">Defaulters</span>
                <p className="defaultersText">{defaulters}</p>
                {defaulters > 0 ? (
                  <Button
                    className="sendReminders btn-sm ms-1"
                    onClick={() => handleReminderModalOpen('general')}
                  >
                    Send Reminders
                  </Button>
                ) : (
                  <></>
                )}

                {/* modal for send reminders */}
                <Modal show={remindersModal} onHide={handleReminderModalClose}>
                  <Modal.Header className="bg-primary text-white" closeButton>
                    {addTemplate ? (
                      <Modal.Title>Add Message Templates</Modal.Title>
                    ) : (
                      <Modal.Title>Send Reminder Message</Modal.Title>
                    )}
                  </Modal.Header>

                  <Modal.Body>
                    <div className="d-flex justify-content-between">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary"
                          size="sm"
                          disabled={isTemplatesEmpty || addTemplate}
                        >
                          {selectedOptionReminder ? (
                            selectedOptionReminder.title
                          ) : (
                            <>Choose Template</>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {messageTemplates.map((template) => (
                            <Dropdown.Item key={template.id} onClick={() => handleOptionSelect(template)}>
                              {template.title}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Button
                        className={`btn btn-sm ${addTemplate ? "btn-primary" : "btn-secondary"
                          }`}
                        onClick={handleAddTemplate}
                      >
                        Add Template
                      </Button>
                    </div>

                    {addTemplate ? (
                      <>
                        <div className="input-icons mt-2 mb-2">
                          <i className="bi bi-chat-left-dots icon"></i>
                          <textarea
                            className="input-field"
                            rows={1}
                            value={customSubject}
                            onChange={(e) => setCustomSubject(e.target.value)}
                          ></textarea>
                        </div>

                        <div className="input-icons">
                          <i className="bi bi-send-plus icon"></i>
                          <textarea
                            className="input-field"
                            rows={8}
                            placeholder="Enter your message"
                            value={customMessage}
                            onChange={(e) => setCustomMessage(e.target.value)}

                          ></textarea>
                        </div>
                      </>

                    ) : selectedOptionReminder ? (

                      <div className="mt-2">
                        <div className="input-icons mb-2">
                          <i className="bi bi-chat-left-dots icon"></i>
                          <textarea
                            className="input-field"
                            rows={1}
                            value={editableSubject ? customSubject : selectedOptionReminder.title}
                            onChange={(e) => setCustomSubject(e.target.value)}
                            readOnly={!editableSubject}
                          ></textarea>
                        </div>

                        <div className="input-icons">
                          <i className="bi bi-send-plus icon"></i>
                          <textarea
                            className="input-field"
                            value={editableMessage ? customMessage : selectedOptionReminder.text}
                            onChange={(e) => setCustomMessage(e.target.value)}
                            readOnly={!editableMessage}
                          ></textarea>
                        </div>
                      </div>


                    ) : (
                      <>
                        <p className="selectionReminderParagraph">Select an option kindly</p>
                      </>
                    )}
                  </Modal.Body>


                  <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="danger" onClick={handleReminderModalClose}>
                      Close
                    </Button>
                    <Button
                      variant="success"
                      onClick={handleSendRemindersGeneral}
                    >
                      Send
                    </Button>
                  </Modal.Footer>
                </Modal>


              </div>
            </Card.Body>
          </Card>

          <Card className="income">
            <Card.Body>
              <Image src={Income}></Image>

              <div className="float-left">
                <span>Income</span>
                <div className="d-flex d-none">
                  <div>
                    <span className={`ms-3`}>Rent Income</span>
                    <span className="">{rentIncome}</span>
                  </div>
                  <div>
                    <span className={`ms-3`}>Utility Income</span>
                    <span className="">{utilityIncome}</span>
                  </div>
                </div>

                <div className="d-flex mt-2">
                  <p className="">{monthIncome?.toLocaleString()}</p>
                  <span className={`ms-3 mt-1 ${textColorClass}`}>
                    {sign}
                    {Math.abs(result)}%
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className="occupacy">
            <Card.Body>
              <Image src={Percentage}></Image>
              <div className="float-left">
                <span>Occupancy</span>
                <div className="d-flex mt-2">
                  <p>{landlordCurrentOccupancy}%</p>
                  <span className={`ms-3 mt-1 ${textColorClassOccupancy}`}>
                    {signOccupancy}
                    {Math.abs(resultOccupancy)}%
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="progressCard">
          <Row>
            {/* Progress bar  */}
            <Col lg={3} md={6} sm={6}>
              <Card className="progressBar">
                <Card.Header>% of Rent Paid</Card.Header>
                <Card.Body>
                  <div className="progress-bar">
                    <svg viewBox="0 0 36 36" className="progress-circle">
                      <path
                        className="progress-circle-background"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path
                        className="progress-circle-progress"
                        strokeDasharray={`${landlordPercentagePaid}, 100`}
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill={`hsl(${landlordPercentagePaid}, 100%, 50%)`}
                      />

                      <text className="progress-circle-text" x="50%" y="50%">
                        {landlordPercentagePaid}%
                      </text>
                    </svg>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={9} md={12} sm={12}>
              <div className="tasks">
                <h5 className="float-start">My List of Properties</h5>
                <Table className="table">
                  <thead className="underline">
                    <th>Image</th>
                    <th>Property</th>
                    <th>Units</th>
                    <th>Occupancy</th>
                    <th>Expected Rent</th>
                    <th>Paid Rent</th>
                    <th>Defaulters</th>
                    <th>Reminders</th>
                  </thead>
                  <tbody>
                    {displayedItems.map((listApartment, index) => (
                      <tr>
                        <td>
                          {selectedApartmentImage[listApartment.id] &&
                            selectedApartmentImage[listApartment.id] ? (
                            <Image
                              src={selectedApartmentImage[listApartment.id]}
                              className="imageTableDashboard"
                              alt={index}
                            ></Image>
                          ) : (
                            <Image
                              src={backgroundImageApartment[listApartment.id]}
                              className="imageTableDashboard"
                            ></Image>
                          )}
                        </td>

                        <td>
                          {/* Render the Link component with the determined path */}
                          <Link
                            className="btn bg-transparent text-primary text-decoration-underline border-0 DashboardLink"
                            to={
                              listApartment.num_houses !== 0
                                ? subscriptionStatus[listApartment.id] &&
                                  subscriptionStatus[listApartment.id] === "No subscription found"
                                  ? `/new-plan/${listApartment.id}`
                                  : subscription[listApartment.id] &&
                                    subscription[listApartment.id].length !== 0 &&
                                    subscription[listApartment.id][0].status !== "paid"
                                    ? `/wallet-data/${listApartment.id}`
                                    : `/selected-property/${listApartment.id}`
                                : `/selected-property/${listApartment.id}`
                            }
                          >
                            {listApartment.name}
                          </Link>
                        </td>




                        <td>{listApartment.num_houses}</td>

                        {rentPercentageApartment[listApartment.id] > 0 ? (
                          <>
                            {rentPercentageApartment[listApartment.id] < 33 ? (
                              <td>
                                <span className={`badge bg-danger p-2`}>
                                  {rentPercentageApartment[listApartment.id]}%
                                </span>
                              </td>
                            ) : rentPercentageApartment[listApartment.id] <
                              67 ? (
                              <td>
                                <span className={`badge bg-warning p-2`}>
                                  {rentPercentageApartment[listApartment.id]}%
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className={`badge bg-success p-2`}>
                                  {rentPercentageApartment[listApartment.id]}%
                                </span>
                              </td>
                            )}
                          </>
                        ) : (
                          <td>
                            <span className={`badge bg-danger p-2`}>0%</span>
                          </td>
                        )}

                        <td>
                          Ksh. {incomeCount[listApartment.id]?.toLocaleString()}
                        </td>

                        <td>
                          Ksh.{" "}
                          {rentPaidApartment[
                            listApartment.id
                          ]?.toLocaleString()}
                        </td>

                        {defaultersApartment[listApartment.id] > 0 ? (
                          <td>{defaultersApartment[listApartment.id]}</td>
                        ) : (
                          <td>0</td>
                        )}

                        {defaultersApartment[listApartment.id] > 0 ? (
                          <td>
                            <Button
                              className="sendReminders btn-sm"
                              onClick={() => handleReminderModalOpen(listApartment.id)}
                            >
                              Send Reminders
                            </Button>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* modal for adding a new subscription */}
                <Modal
                  show={subscriptionModal}
                  onHide={handleCloseSubscription}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title
                      style={{ fontSize: "1.5rem", color: "#1F57F8" }}
                    >
                      Choose your best subscription plan
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {subscriptionAmount[subscriptionId] && (
                      <Row>
                        <Col md={3}>
                          <Button
                            style={{
                              height: "10vh",
                              width: "10vw",
                              display: "block",
                              textAlign: "left",
                            }}
                            className="btn btn-info text-white"
                            onClick={() =>
                              handleSubscriptionSelect("monthly", 0)
                            }
                          >
                            <b className="mt-1">Monthly</b>
                            <br></br>
                            <span>
                              Ksh.{" "}
                              {subscriptionAmount[
                                subscriptionId
                              ].monthly.toLocaleString()}
                            </span>
                          </Button>
                        </Col>
                        <Col md={3}>
                          <span className="discountSpan">10%</span>
                          <Button
                            style={{
                              height: "10vh",
                              width: "10vw",
                              display: "block",
                              textAlign: "left",
                              background: "#adb1b4",
                            }}
                            className="btn btn-secondary border-0"
                            onClick={() =>
                              handleSubscriptionSelect("quartely", 10)
                            }
                          >
                            <b className="mt-1">Quartely</b>
                            <br></br>
                            <span>
                              Ksh.{" "}
                              {subscriptionAmount[
                                subscriptionId
                              ].quartely.toLocaleString()}
                            </span>
                          </Button>
                        </Col>
                        <Col md={3}>
                          <span className="discountSpanSemi">20%</span>
                          <Button
                            style={{
                              height: "10vh",
                              width: "12vw",
                              display: "block",
                              textAlign: "left",
                            }}
                            className="btn btn-warning text-white"
                            onClick={() =>
                              handleSubscriptionSelect("semi_annually", 20)
                            }
                          >
                            <b className="mt-1">Semi-Annually</b>
                            <br></br>
                            <span>
                              Ksh.{" "}
                              {subscriptionAmount[
                                subscriptionId
                              ].semi_annually.toLocaleString()}
                            </span>
                          </Button>
                        </Col>

                        <Col md={3}>
                          <span className="discountSpanAnnually">30%</span>
                          <Button
                            style={{
                              height: "10vh",
                              width: "10vw",
                              display: "block",
                              textAlign: "left",
                              background: "#9b59b6",
                            }}
                            className="btn border-0 btn-success"
                            onClick={() =>
                              handleSubscriptionSelect("annually", 30)
                            }
                          >
                            <b className="mt-1">Annually</b>
                            <br></br>
                            <span>
                              Ksh.{" "}
                              {subscriptionAmount[
                                subscriptionId
                              ].annually.toLocaleString()}
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <p></p>
                    {selectedSubscriptionAmount && (
                      <span className="mt-2">
                        Your have selected the <span className="text-capitalize">{selectedSubscriptionPeriod}</span>{" "}
                        subscription that will cost Ksh.{" "}
                        {selectedSubscriptionAmount}{" "}
                        <span className="text-capitalize">{selectedSubscriptionPeriod}</span> and has a discount of{" "}
                        {selectedSubscriptionDiscount}%
                      </span>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-success opacity-75"
                      onClick={handleAddSubscription}
                    >
                      Create Plan
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* modal for paying subscription */}
                <Modal
                  show={subscriptionPay}
                  onHide={handleCloseSubscriptionPay}
                >
                  <Modal.Header closeButton>
                    <Modal.Title
                      style={{ fontSize: "1.5rem", color: "rgb(31 248 129)" }}
                    >
                      Subscription payment
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex place-content-center justify-content-between mx-auto">
                      <Button
                        className="border-0"
                        style={{ background: "#f01de8" }}
                      // onClick={() => handleSubscriptionPaymentWalletOpen(invoice.id)}
                      >
                        Make payment now
                      </Button>
                      <Button
                        className="border-0 btn btn-warning opacity-75 text-white"
                        onClick={handleCloseSubscriptionPay}
                      >
                        Pay Later
                      </Button>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-secondary text-white fw-bold opacity-75"
                      onClick={handleAddSubscription}
                    >
                      Done
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* modal for displaying the invoices */}
                <Modal
                  show={subscriptionModalInvoice}
                  onHide={handleCloseSubscriptionInvoice}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title
                      style={{ fontSize: "1.5rem", color: "rgb(31 248 129)" }}
                    >
                      Subscription Invoices
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="fw-bold text-info text-uppercase">
                      Kindly pay so that you can have access to your property
                      data
                    </p>
                    <Table
                      className="t"
                      style={{ borderBottom: "1px solid white" }}
                    >
                      <thead className="underline">
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Wallet</th>
                        <th>Month</th>
                        <th>Year</th>
                        <th>Actions</th>
                      </thead>
                      <tbody>
                        {invoices.map((invoice) => (
                          <tr>
                            <td>{invoice.amount} </td>
                            <td>{invoice.status} </td>
                            <td>Subscription </td>
                            <td>{invoice.month} </td>
                            <td>{invoice.year} </td>
                            <td>
                              <Button
                                className="btn btn-sm border-0"
                                style={{ background: "rgb(240 204 29)" }}
                                onClick={() =>
                                  handleSubscriptionPaymentOpen(invoice.id)
                                }
                              >
                                Make payment
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-secondary text-white fw-bold opacity-75"
                      onClick={handleAddSubscription}
                    >
                      Done
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* modal payment process */}
                <Modal
                  show={subscriptionPaymentModal}
                  onHide={handleSubscriptionPaymentClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title
                      style={{ fontSize: "1.5rem", color: "#0dcaf0" }}
                    >
                      Choose payment method
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Your subscription balance is {invoicesBalance}</p>
                    {apartmentWallets.length !== 0 ? (
                      <div className="d-flex justify-content-between mb-3">
                        <Button
                          className="border-0"
                          style={{ background: "#343a4080" }}
                          onClick={() => handlePaymentMethod("wallet")}
                        >
                          Wallet
                        </Button>
                        <Button
                          className="border-0"
                          style={{ background: "rgb(255 215 26)" }}
                          onClick={() => handlePaymentMethod("mpesa")}
                        >
                          {" "}
                          Mpesa
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <Button
                          className="btn btn-sm  text-white border-0"
                          style={{ background: "#9b59b6" }}
                          onClick={() => handleMpesaOption("my_number")}
                        >
                          Pay with my number
                        </Button>
                        <Button
                          className="btn btn-sm text-white border-0"
                          style={{ background: "#34495e" }}
                          onClick={() => handleMpesaOption("other_number")}
                        >
                          Pay with another number
                        </Button>
                      </div>
                    )}

                    {selectedPaymentMethod !== null ? (
                      selectedPaymentMethod === "wallet" ? (
                        <>
                          <FormSelect
                            className="mt-2"
                            value={selectedWallet}
                            onChange={(e) => handleWalletSelect(e.target.value)}
                          >
                            <option selected disabled value="">
                              Select wallet to pay from
                            </option>
                            {apartmentWallets.map((wallet) => (
                              <option key={wallet.id} value={wallet.id}>
                                {wallet.name}
                              </option>
                            ))}
                          </FormSelect>
                          {selectedWalletName.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {selectedWalletBalance === 0 ? (
                                <p className="mt-2">
                                  Sorry, {selectedWalletName.name} does not have
                                  any money in it to make payment
                                </p>
                              ) : (
                                <p className="mt-2">
                                  {selectedWalletName.name} has a balance of
                                  Ksh. {selectedWalletBalance}
                                </p>
                              )}
                            </>
                          )}
                          {selectedWalletBalance === 0 ? (
                            <></>
                          ) : (
                            <>
                              <input
                                type="number"
                                style={{ borderRadius: "5px" }}
                                className="p-2 w-100 mt-2"
                                placeholder="Enter amount to pay"
                                value={enteredAmount}
                                onChange={(e) =>
                                  handleAmountChange(e.target.value)
                                }
                              />

                              {enteredAmount ? (
                                <>
                                  {selectedWalletBalance < enteredAmount ? (
                                    <p className="mt-2">
                                      Sorry Insuficient Balanace
                                    </p>
                                  ) : (
                                    <p className="mt-2">
                                      Balance in {selectedWalletName.name} will
                                      be {selectedWalletBalance - enteredAmount}{" "}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <> </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <Button
                            className="btn btn-sm text-white border-0"
                            style={{ background: "#9b59b6" }}
                            onClick={() => handleMpesaOption("my_number")}
                          >
                            Pay with my number
                          </Button>
                          <Button
                            className="btn btn-sm  text-white border-0"
                            style={{ background: "#34495e" }}
                            onClick={() => handleMpesaOption("other_number")}
                          >
                            Pay with another number
                          </Button>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                    <Form>
                      {selectedMpesaOption !== null ? (
                        <>
                          {selectedMpesaOption === "my_number" ? (
                            <div className="d-block">
                              <input
                                value={userLogedIn.phone_number}
                                type="number"
                                style={{ borderRadius: "5px" }}
                                className="p-2 w-100 mt-2"
                                placeholder="Enter amount to pay"
                              />
                              <input
                                type="number"
                                value={amountPhone}
                                name="amount_phone"
                                onChange={handleAmountPayment}
                                style={{ borderRadius: "5px" }}
                                className="p-2 w-100 mt-2"
                                placeholder="Enter amount to pay"
                              />
                            </div>
                          ) : (
                            <div className="d-block">
                              <input
                                type="number"
                                value={phoneNumberPayment}
                                name="phone_number"
                                onChange={handlePhoneNumberChange}
                                style={{ borderRadius: "5px" }}
                                className="p-2 w-100 mt-2"
                                placeholder="Enter phone number"
                              />
                              <input
                                type="number"
                                value={amountPhone}
                                name="amount_phone"
                                onChange={handleAmountPayment}
                                style={{ borderRadius: "5px" }}
                                className="p-2 w-100 mt-2"
                                placeholder="Enter amount to pay"
                              />
                            </div>
                          )}
                          {amountPhone && (
                            <p className="mt-2 text-warning">The subscription balance will be {mpesSubscriptionBalance}</p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-success text-white fw-bold opacity-75"
                      onClick={handlePaySubscription}
                    >
                      Pay
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div className="pagination">
                  <button onClick={handlePrevPage} disabled={currentPage === 0}>
                    &lt;
                  </button>
                  <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length
                    }`}</span>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages - 1}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* final table that has graphs*/}
        <TableJs />
      </div>
      <footer className="footer">
        &copy; {currentYear} Kodi
        <div className="footers_items">
          <span>About</span>
          <span>Suport</span>
          <span>Contact Us</span>
        </div>
      </footer>
    </>
  );
}

export default Dashboard;
