import React, { useState, useEffect } from 'react'
import './OverflowReport.scss'
import { Card, Table, Modal, Button, Form, FormControl, FormSelect } from 'react-bootstrap';
import Header from '../Header';
import SideBar from '../SideBar';
import { useParams } from 'react-router-dom';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';


function OverflowReport() {
  // getting the id and finding the apparment details
  const { id } = useParams();
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // houses within the apartment
  const [ctobTransactions, setCtobTransactions] = useState([])

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }

  // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
  useEffect(() => {
    // get the ctob transactions
    serverUrl.get(`/landlord/c2btranscation/?apartment=${id}`, config)
      .then((res) => {
        // Filter out the transactions with reconciled as true and overflow amount greater than 0
        const filteredTransactions = res.data.results.filter(transaction => transaction.overflow_amount > 0);
        // Set the filtered transactions as the value of setCtobTransactions
        setCtobTransactions(filteredTransactions);
        console.log(filteredTransactions)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = ctobTransactions.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  // modal for adding reconciliation
  const [showModal, setShowModal] = useState(false)
  const [recordId, setRecordId] = useState('')

  const handleOpenModal = (record) => {
    setShowModal(true);
    setRecordId(record.id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const updateReconcile = () => {
    updateRecord(recordId);
  }
  // done button reloading the page
  const handleCloseModalReload = () => {
    setShowModal(false);
    window.location.reload()
  };

  const exportCSV = () => {
    const csvData = [
      ['Paid Number', 'Paid By', 'Amount Paid', 'Overflow Amount', 'Transaction Id', 'Transaction Type', 'Date Paid'],
      ...ctobTransactions.map((item) => [item.phone_number, item.first_name, item.transcation_amount, item.overflow_amount, item.transcation_id, item.transcation_type, item.transcation_time]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'overflow_report.csv');
  };

  const updateRecord = (recordId) => {
    // Make an API request to update the reconcile data
    const updateData = {
      overflow_amount: '0'
    }
    serverUrl.patch(`/landlord/c2btranscation/${recordId}/`, updateData, config)
      .then((res) => {
        toast.success('The Amount Is Succesfully Removed From Our Tables!')
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      });
  };

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='overflowReport'>
        <div className='tasks ' >
          <h2 style={{ color: "#B33771" }}>Overpayment Report</h2>

          <div className='d-flex justify-content-between'>

            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              <Button className='btn btn-danger text-white d-none' >PDF <i className='bi bi-file-pdf-fill'></i></Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>

          <Table className='table'>
            <thead className='underline'>
              <th onClick={() => handleSort("phone_number")}>Paid Number<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("first_name")}>Paid By<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("bill_ref_number")}>Unit<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_amount")}>Amount Paid<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("overflow_amount")}>Overflow Amount<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_id")}>Transaction Id<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_type")}>Transaction Type<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("transcation_time")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
              {/* <th>Reconcile Transaction</th> */}
            </thead>
            <tbody>
              {displayedItems.map((Defaulter, index) => (
                <tr key={index}>
                  <td>{Defaulter.phone_number}</td>
                  <td>{Defaulter.first_name}</td>
                  <td>{Defaulter.bill_ref_number}</td>
                  <td>{Defaulter.transcation_amount}</td>
                  <td>{Defaulter.overflow_amount}</td>
                  <td>{Defaulter.transcation_id}</td>
                  <td>{Defaulter.transcation_type}</td>
                  <td>
                    {isNaN(new Date(Defaulter.transcation_time)) ? (
                      `${Defaulter.transcation_time.substr(6, 2)}-${Defaulter.transcation_time.substr(4, 2)}-${Defaulter.transcation_time.substr(0, 4)}`
                    ) : (
                      new Date(Defaulter.transcation_time).toLocaleDateString()
                    )}
                  </td>


                  {/* <td className='btn btn-success bg-success btn-sm text-white mt-3' onClick={() => handleOpenModal(Defaulter)}>Reconcile</td> */}
                </tr>

              ))}
            </tbody>
          </Table>

          {/* modal used in adding the transaction */}
          <Modal Modal show={showModal} onHide={handleCloseModal} >
            <Modal.Header closeButton>
              <Modal.Title>Revise Overflow{recordId}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-between align-self-auto'>
              <Button className='btn btn-sm btn-warning text-white' onClick={updateReconcile}>Return Overflow</Button>
              <Button className='btn btn-sm btn-info text-white'>Reconcile</Button>
            </Modal.Body>
            <Modal.Footer>

              <Button variant="success" onClick={handleCloseModalReload} >
                DONE
              </Button>
            </Modal.Footer>
          </Modal>
          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${ctobTransactions.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>

          </div>
        </div>
      </div >
    </>
  )
}

export default OverflowReport