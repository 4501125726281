import React, { useState, useRef, useEffect } from 'react';
import './SelectedWalletPlan.scss';
import Header from './Header';
import SideBar from './SideBar';
import { Card, Image, Table, Nav, Button, Modal, Row, Col, FormSelect, Form } from 'react-bootstrap';
import Book from "../../assets/img/book.png";
import Due from "../../assets/img/due-date.png";
import Percentage from "../../assets/img/discount.png";
import Income from "../../assets/img/salary.png";
import Lottie from 'react-lottie';
import SadEmoji from '../../assets/lottie/sad_emoji.json'
import { useParams } from 'react-router-dom';
import { serverUrl } from '../../serverUrl';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useTheme } from './ThemeContext';

function SelectedWalletPlan() {
    const [showSidebar, setShowSidebar] = useState(true);
    const [activeTab, setActiveTab] = useState('invoices');
    const [modalTitle, setModalTitle] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalInactive, setShowModalInactive] = useState(false);
    const { id } = useParams()
    const { theme, toggleTheme } = useTheme();


    // get the user who is logged in and the date related to them
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    // get the invoices of that wallet
    const [invoices, setInvoices] = useState([])
    const [paidInvoices, setPaidInvoices] = useState([])

    const [wallet, setWallet] = useState({})
    const [userLogedIn, setUserLogedIn] = useState([]);
    const [totalDue, setTotalDue] = useState('');
    const [invoiceCount, setInvoiceCount] = useState('');
    const [walletSummary, setWalletSummary] = useState({});

    useEffect(() => {
        // get the landlord logged
        serverUrl
            .get(`/users/users-details/?id=${userId}`, config)
            .then((res) => {
                setUserLogedIn(res.data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });
        // get the wallet summary
        serverUrl.get(`/landlord/selected-wallet-summary/?wallet_id=${id}`, config)
            .then((res) => {
                setWalletSummary(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
        // get the wallet data
        serverUrl
            .get(`/landlord/wallet/${id}/`, config)
            .then((res) => {
                setWallet(res.data);
                
            })
            .catch((error) => {
                console.log(error);
            });

        // get total due
        serverUrl
            .get(`/landlord/generate_pdf_subscription_invoice/?wallet_id=${id}`, config)
            .then((res) => {
                setTotalDue(res.data.sum_total);
            })
            .catch((error) => {
                console.log(error);
            });
        // get the wallet invoices

        serverUrl
            .get(`/landlord/subscriptions-invoice/?wallet=${id}`, config)
            .then((res) => {
                const invoices = res.data.results;

                // Filter invoices with status "unpaid" or "partial"
                const filteredInvoices = invoices.filter(
                    (invoice) => invoice.status === "unpaid" || invoice.status === "partial"
                );
                if (filteredInvoices.length) {
                    setShowModalInactive(true)
                }
                const filteredInvoicesPaid = invoices.filter(
                    (invoice) => invoice.status === "paid"
                );
                // Fetch subscription details for each invoice
                const fetchSubscriptions = filteredInvoices.map((invoice) => {
                    return serverUrl
                        .get(`/landlord/subscriptions?id=${invoice.subscription}`, config)
                        .then((res) => {
                            const subscriptionName = res.data.results[0].duration;
                            const subscriptionAmount = res.data.results[0].amount;

                            return { ...invoice, subscription_name: subscriptionName, subscription_amount: subscriptionAmount };
                        })
                        .catch((error) => {
                            console.log(error);
                            return invoice;
                        });
                });

                Promise.all(fetchSubscriptions)
                    .then((invoicesWithSubscriptions) => {
                        setInvoiceCount(invoicesWithSubscriptions.length)
                        setInvoices(invoicesWithSubscriptions);
                    })
                    .catch((error) => {
                        console.log(error);
                        setInvoices(invoices);
                    });
                // add the data for the subscription
                const fetchSubscriptionsPaid = filteredInvoicesPaid.map((invoice) => {
                    return serverUrl
                        .get(`/landlord/subscriptions?id=${invoice.subscription}`, config)
                        .then((res) => {
                            const subscriptionName = res.data.results[0].duration;
                            const subscriptionAmount = res.data.results[0].amount;

                            return { ...invoice, subscription_name: subscriptionName, subscription_amount: subscriptionAmount };
                        })
                        .catch((error) => {
                            console.log(error);
                            return invoice;
                        });
                });

                Promise.all(fetchSubscriptionsPaid)
                    .then((invoicesWithSubscriptions) => {
                        setPaidInvoices(invoicesWithSubscriptions)

                    })
                    .catch((error) => {
                        console.log(error);
                        setPaidInvoices(filteredInvoicesPaid)
                    });
            })
            .catch((error) => {
                console.log(error);
            });


    }, [userId]);

    // payment for subscription process
    const [selectedApartmentIdInvoice, setSelectedApartmentIdInvoice] =
        useState("");
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedCurrentWalletBalance, setSelectedCurrentWalletBalance] =
        useState(null);
    const [subscriptionPaymentModal, setSubscriptionPaymentModal] =
        useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedMpesaOption, setSelectedMpesaOption] = useState(null);
    const [amountPhone, setAmountPhone] = useState("");
    const [enteredAmount, setEnteredAmount] = useState("");
    const [phoneNumberPayment, setPhoneNumberPayment] = useState("");

    const mpesSubscriptionBalance = parseInt(walletSummary.total_unpaid) - parseInt(amountPhone)
    const [apartmentWallets, setApartmentWallets] = useState([]);
    const [selectedWalletName, setSelectedWalletName] = useState([]);
    const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
    const [invoiceData, setInvoiceData] = useState({});
    const [subscriptionModalInvoice, setSubscriptionModalInvoice] =
        useState(false);

    const handleMpesaOption = (method) => {
        setSelectedMpesaOption(method);
    };
    const handleAmountPayment = (e) => {
        setAmountPhone(e.target.value);
    };


    const handlePhoneNumberChange = (e) => {
        setPhoneNumberPayment(e.target.value);
    };

    const handleAmountChange = (amount) => {
        setEnteredAmount(amount);
    };

    const handleSubscriptionPaymentClose = () => {
        setSubscriptionPaymentModal(false);
        setSelectedPaymentMethod(null);
        setSelectedMpesaOption(null);
    };
    const handlePaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        setSelectedWalletName([]);
        handleMpesaOption(null);
    };

    function handleWalletSelect(selectedWalletId) {
        setSelectedWallet(selectedWalletId);
        serverUrl
            .get(
                `/landlord/wallet-account-balance?wallet=${selectedWalletId}`,
                config
            )
            .then((res) => {
                setSelectedWalletBalance(res.data.results[0].wallet_balance);
                const balancePayment =
                    res.data.results[0].wallet_balance - parseInt(invoiceData.amount);
                setSelectedCurrentWalletBalance(balancePayment);

                const balance = parseInt(invoiceData.amount) - balancePayment;
            })
            .catch((error) => {
                console.log(error);
            });

        // get the details of the selected wallet
        serverUrl
            .get(`/landlord/wallet/${selectedWalletId}/`, config)
            .then((res) => {
                setSelectedWalletName(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePaySubscription = () => {
        if (selectedPaymentMethod === "wallet") {
            const data = {
                subscription_wallet: parseInt(selectedWallet),
                wallet: invoiceData.wallet,
                property: wallet.property,
                wallet_balance: parseInt(selectedWalletBalance) - parseInt(enteredAmount),
                wallet_name: selectedWalletName.name,
                invoice: parseInt(invoiceData.id),
                amount_paid: parseInt(enteredAmount),
            };
            const formData = new FormData();

            // Append each key-value pair to the FormData object
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, String(data[key]));
                }
            }
            // post the data for the payment
            serverUrl
                .post(`/landlord/pay-subscription-from-wallet/`, formData, config)
                .then((res) => {
                    // show paid and close the modal
                    toast.success("Succesfuly made payment for the subscription");
                    setEnteredAmount(null);
                    setSelectedWallet(null);
                    setSelectedWalletName(null);
                    setSelectedPaymentMethod(null);
                    setSubscriptionPaymentModal(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        else {
            let modifiedPhoneNumber = "";

            if (selectedMpesaOption === "my_number") {
                modifiedPhoneNumber = userLogedIn.phone_number;

                if (userLogedIn.phone_number.length === 10 && userLogedIn.phone_number.startsWith("0")) {
                    modifiedPhoneNumber = "254" + userLogedIn.phone_number.slice(1);
                }
            } else if (selectedMpesaOption === "other_number") {
                modifiedPhoneNumber = phoneNumberPayment;

                if (phoneNumberPayment.length === 10 && phoneNumberPayment.startsWith("0")) {
                    modifiedPhoneNumber = "254" + phoneNumberPayment.slice(1);
                }
            }

            const data = {
                amount: parseInt(amountPhone),
                phone_number: modifiedPhoneNumber,
                current_balance: parseInt(invoiceData.amount) - amountPhone,
                wallet: invoiceData.wallet,
                subscription_plan_id: invoiceData.subscription,
                invoice_id: parseInt(invoiceData.id),
                property_id: invoiceData.apartment

            };
            console.log(data)

        }
    };
    const handleOpenSubscriptionInvoice = (invoiceId) => {
        setSubscriptionPaymentModal(true);

        // get the details of the selected invoice
        serverUrl
            .get(`/landlord/subscriptions-invoice/${invoiceId}/`, config)
            .then((res) => {
                setInvoiceData(res.data);
                // get the wallets for the apartment
                serverUrl
                    .get(`/landlord/wallet/?property=${res.data.apartment}`, config)
                    .then((res) => {
                        setApartmentWallets(
                            res.data.results.filter(
                                (wallet) => wallet.account_type !== "subscription"
                            )
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    };
    const handleCloseSubscriptionInvoice = () => {
        setSubscriptionModalInvoice(false);
    };
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }
    const [type, setType] = useState('')
    const handleButtonClick = (title, type) => {
        setModalTitle(title);
        setType(type)
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }


    const handleCloseModalInactive = () => {

        setShowModalInactive(false);
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SadEmoji,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const [flashing, setFlashing] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFlashing(prevState => !prevState);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='selected_plan'>
                {walletSummary && (
                    <div className="staticCards">
                        <Card className="rentDue">
                            <Card.Body>
                                <Image src={Due} />
                                <div className="float-left">
                                    <span>Total Paid</span>
                                    <div className="d-flex mt-2">
                                        <p>Ksh. {walletSummary.total_paid?.toLocaleString()}</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="defaulters">
                            <Card.Body>
                                <Image src={Book} />
                                <div className="float-left">
                                    <span className="defaultersText">Total Due</span>
                                    <p>Ksh. {walletSummary.total_unpaid?.toLocaleString()}</p>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="income">
                            <Card.Body>
                                <Image src={Income} />
                                <div className="float-left">
                                    <span>Active Months</span>
                                    <div className="d-flex mt-2">
                                        <p>{walletSummary.active_months}</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="occupacy">
                            <Card.Body>
                                <Image src={Percentage} />
                                <div className="float-left">
                                    <span>Favourite Plan</span>
                                    <div className="d-flex mt-2">
                                        <p style={{ textTransform: 'capitalize' }}>{walletSummary.favorite_plan_duration}</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                )}




                <Nav variant="tabs" defaultActiveKey="invoices" className="mb-4 mt-4">
                    <Nav.Item>
                        <Nav.Link eventKey="invoices" onClick={() => handleTabChange('invoices')}>Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="paidInvoices" onClick={() => handleTabChange('paidInvoices')}>Paid Invoices</Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeTab === 'invoices' && (
                    <div className="tasks">
                        <h5 className="float-start">My List of Invoices for {wallet.name}</h5>
                        <Table className="table">
                            <thead className="underline">
                                <tr>
                                    <th>Plan</th>
                                    <th>Invoice Month</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.subscription_name}</td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.month}</td>
                                        <td>Ksh. {invoice.amount.toLocaleString()}</td>
                                        {invoice.status === "unpaid" ?
                                            <td className='text-danger' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                            : invoice.status === "partial" ?
                                                <td className='text-warning' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                                :
                                                <td className='text-success' style={{ textTransform: "capitalize" }}>{invoice.status}</td>

                                        }
                                        <td className='d-flex '>
                                            <Button
                                                className={`btn btn-sm btn-info text-white bg-info ms-4 me-4 ${flashing ? 'flashing-button' : ''}`}
                                                onClick={() => handleOpenSubscriptionInvoice(invoice.id)}
                                            >
                                                Pay Invoice
                                            </Button>
                                            <Button className='btn btn-sm btn-success text-white bg-success me-2' onClick={() => handleButtonClick(invoice, 'Invoice')}><i className='bi bi-eye'></i> Invoice</Button>
                                            <Button className='btn btn-sm btn-warning text-white bg-warning' onClick={() => handleButtonClick('Download Invoice')}><i className='bi bi-download'></i> Invoice</Button>

                                        </td>


                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </div>
                )}

                {activeTab === 'paidInvoices' && (
                    <div className="tasks">
                        <h5 className="float-start">My List of Paid Invoices for {wallet.name}</h5>
                        <Table className="table">
                            <thead className="underline">
                                <tr>
                                    <th>Date Paid</th>
                                    <th>Plan</th>
                                    <th>Invoice Month</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paidInvoices.map((invoice) => (
                                    <tr>
                                        <td>
                                            {new Date(invoice.updated_at).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit'
                                            })}
                                        </td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.subscription_name}</td>
                                        <td style={{ textTransform: "capitalize" }}>{invoice.month}</td>
                                        <td>Ksh. {invoice.amount.toLocaleString()}</td>
                                        {invoice.status === "unpaid" ?
                                            <td className='text-danger' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                            : invoice.status === "partial" ?
                                                <td className='text-warning' style={{ textTransform: "capitalize" }}>{invoice.status}</td>
                                                :
                                                <td className='text-success' style={{ textTransform: "capitalize" }}>{invoice.status}</td>

                                        }
                                        <td className='d-flex '>
                                            <Button className='btn btn-sm btn-success text-white bg-success me-2' onClick={() => handleButtonClick(invoice, 'Receipts')}><i className='bi bi-eye'></i> Receipt</Button>
                                            <Button className='btn btn-sm btn-warning text-white bg-warning' onClick={() => handleButtonClick('Download Invoice')}><i className='bi bi-download'></i> Receipt</Button>
                                        </td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                )}


                {/* modal payment process */}
                <Modal
                    show={subscriptionPaymentModal}
                    onHide={handleSubscriptionPaymentClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title
                            style={{ fontSize: "1.5rem", color: "#0dcaf0" }}
                        >
                            Choose payment method
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Your subscription balance is <b>Ksh. {walletSummary.total_unpaid?.toLocaleString()}</b></p>
                        {apartmentWallets.length !== 0 ? (
                            <div className="d-flex justify-content-between mb-3">
                                <Button
                                    className="border-0"
                                    style={{ background: "#343a4080" }}
                                    onClick={() => handlePaymentMethod("wallet")}
                                >
                                    Wallet
                                </Button>
                                <Button
                                    className="border-0"
                                    style={{ background: "rgb(255 215 26)" }}
                                    onClick={() => handlePaymentMethod("mpesa")}
                                >
                                    {" "}
                                    Mpesa
                                </Button>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between">
                                <Button
                                    className="btn btn-sm  text-white border-0"
                                    style={{ background: "#9b59b6" }}
                                    onClick={() => handleMpesaOption("my_number")}
                                >
                                    Pay with my number
                                </Button>
                                <Button
                                    className="btn btn-sm text-white border-0"
                                    style={{ background: "#34495e" }}
                                    onClick={() => handleMpesaOption("other_number")}
                                >
                                    Pay with another number
                                </Button>
                            </div>
                        )}

                        {selectedPaymentMethod !== null ? (
                            selectedPaymentMethod === "wallet" ? (
                                <>
                                    <FormSelect
                                        className="mt-2"
                                        value={selectedWallet}
                                        onChange={(e) => handleWalletSelect(e.target.value)}
                                    >
                                        <option selected disabled value="">
                                            Select wallet to pay from
                                        </option>
                                        {apartmentWallets.map((wallet) => (
                                            <option key={wallet.id} value={wallet.id}>
                                                {wallet.name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                    {selectedWalletName.length === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            {selectedWalletBalance === 0 ? (
                                                <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                    Sorry, {selectedWalletName.name} does not have
                                                    any money in it to make payment
                                                </p>
                                            ) : (
                                                <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                    {selectedWalletName.name} has a balance of
                                                    Ksh. {selectedWalletBalance?.toLocaleString()}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {selectedWalletBalance === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <input
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                                value={enteredAmount}
                                                onChange={(e) =>
                                                    handleAmountChange(e.target.value)
                                                }
                                            />

                                            {enteredAmount ? (
                                                parseInt(enteredAmount) === 0 ?
                                                    <p className="mt-2 text-danger fw-bold">
                                                        You cannot make a payment of Ksh. 0.
                                                    </p>
                                                    :
                                                    <>
                                                        {selectedWalletBalance < enteredAmount ? (
                                                            <p className="mt-2 text-danger fw-bold">
                                                                Sorry, you have insufficient funds in your account.
                                                            </p>
                                                        ) : (
                                                            <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "goldenrod" }}>
                                                                After this transaction, your balance in {selectedWalletName.name} will be Ksh. {(selectedWalletBalance - enteredAmount)?.toLocaleString()}.
                                                            </p>
                                                        )}
                                                    </>
                                            ) : (
                                                <></> // No message displayed when amount is not entered
                                            )}



                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="btn btn-sm text-white border-0"
                                        style={{ background: "#9b59b6" }}
                                        onClick={() => handleMpesaOption("my_number")}
                                    >
                                        Pay with my number
                                    </Button>
                                    <Button
                                        className="btn btn-sm  text-white border-0"
                                        style={{ background: "#34495e" }}
                                        onClick={() => handleMpesaOption("other_number")}
                                    >
                                        Pay with another number
                                    </Button>
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                        
                        <Form>
                            {selectedMpesaOption !== null ? (
                                <>
                                    {selectedMpesaOption === "my_number" ? (
                                        <div className="d-block">
                                            <input
                                                value={userLogedIn.phone_number}
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-block">
                                            <input
                                                type="number"
                                                value={phoneNumberPayment}
                                                name="phone_number"
                                                onChange={handlePhoneNumberChange}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter phone number"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    )}
                                    {amountPhone && (
                                        <p className="mt-2 text-warning">The subscription balance will be {mpesSubscriptionBalance}</p>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-success text-white fw-bold opacity-75"
                            onClick={handlePaySubscription}
                        >
                            Pay
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* inactive subscription modal */}
        <Modal className={`inactiveModal-${theme}`} show={showModalInactive} onHide={handleCloseModalInactive} style={{ marginTop: "7.5rem" }} size='lg'>

                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="text-center border-0 position-relative">
                            <div className="card-background"></div>
                            <div className='card-body-content'>
                                <h3 className='text-uppercase'>You plan is not activated</h3>
                                <p className='w-75 text-center mx-auto'>Your have uncleared invoices kindly pay them so that you can continue accessing our services</p>
                                <div className="d-flex justify-content-center">
                                    <Lottie
                                        options={defaultOptions}
                                        height={200}
                                        width={400}
                                    />
                                </div>
                                <p>We do not want to lose you kindly activate your plan</p>
                                <Button className='w-auto d-flex text-center mb-4 ' style={{ margin: "0 auto" }}>ACTIVATE</Button>
                            </div>
                        </Card>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalInactive}>Close</Button>
                    </Modal.Footer> */}
                </Modal>


                {/* Modal */}
                <Modal show={showModal} onHide={handleCloseModal} size='xl'>
                    <Modal.Header closeButton>
                        {type === 'Receipts' ?
                            <Modal.Title>RECEIPT</Modal.Title>

                            :
                            <Modal.Title>INVOICE</Modal.Title>

                        }
                    </Modal.Header>
                    <Modal.Body>
                        {modalTitle ? (
                            <Card style={{ overflow: "hidden", border: "none" }}>
                                <div className="blue-ribbon"></div>
                                <h3 className='px-4 mt-3 logoDisplay'>KODI</h3>
                                <Row className='px-4'>
                                    <Col md={8}>
                                        <h5 className='fw-bold mb-2'>RECEIPIENT</h5>
                                        <div className='d-grid'>
                                            <span>{userLogedIn.full_name}</span>
                                            <span>{userLogedIn.phone_number} </span>
                                            <span>{userLogedIn.email}</span>
                                        </div>
                                    </Col>

                                    <Col md={4} className='mt-3'>
                                        {type === 'Receipts' ?
                                            <h5 className='fw-bold mb-2'>RECEIPT</h5>

                                            :
                                            <h5 className='fw-bold mb-2'>INVOICE</h5>

                                        }
                                        <div className='d-grid'>
                                            <span>{modalTitle.id}/{modalTitle.created_at ? modalTitle.created_at.slice(0, 10) : ''}</span>
                                            {modalTitle.status && (
                                                <span className={`text-${modalTitle.status === "partial" ? "warning" : modalTitle.status === "paid" ? "success" : "danger"} fw-bold`} style={{ textTransform: "capitalize" }}>{modalTitle.status}</span>
                                            )}
                                            <span>Ksh. {modalTitle.amount && modalTitle.amount > 0 ? modalTitle.amount.toLocaleString() : modalTitle.amount}</span>
                                        </div>
                                    </Col>
                                </Row>
                                {type === 'Receipts' ?
                                    <></>
                                    :
                                    <h3 className='align-self-center fw-bold mt-4 mb-4'>Total due : <b style={{ textTransform: "capitalize" }}>{totalDue}</b></h3>
                                }
                                <Row className=''>
                                    <Col md={3}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='topHeader'>Invoice Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='fw-bold'>{modalTitle.created_at ? modalTitle.created_at.slice(0, 10) : ''}</td>
                                                </tr>
                                                {type === 'Receipts' ?
                                                    <></>
                                                    :
                                                    <tr>
                                                        <td className='fw-bold'>
                                                            Due Date<br />
                                                            <b className='text-danger fw-bold'>
                                                                {modalTitle.created_at && (() => {
                                                                    // Parse the existing date string into a Date object
                                                                    const currentDate = new Date(modalTitle.created_at);

                                                                    // Add 7 days to the current date
                                                                    const nextWeekDate = new Date(currentDate);
                                                                    nextWeekDate.setDate(nextWeekDate.getDate() + 7);

                                                                    // Format the next week date to display in the desired format
                                                                    const formattedNextWeekDate = nextWeekDate.toISOString().slice(0, 10);

                                                                    return formattedNextWeekDate;
                                                                })()}

                                                            </b>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>

                                    <Col md={9}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='topHeader'>Subscription Plan</th>
                                                    <th className='topHeader'>Balance</th>
                                                    <th className='topHeader'>Total plan amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textTransform: "capitalize" }}>{modalTitle.subscription_name}</td>
                                                    <td>Ksh. {modalTitle.amount && modalTitle.amount > 0 ? modalTitle.amount.toLocaleString() : modalTitle.amount}</td>
                                                    <td>Ksh. {modalTitle.subscription_amount}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {type === 'Invoice' ?
                                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                                <div>
                                                    <h5 className='fw-bold'>Paying for your invoice</h5>
                                                    <ul className='w-75 d-grid' style={{ fontSize: "0.9rem" }}>
                                                        <li style={{ listStyleType: "auto" }}>Make sure you have sufficient funds in your wallet or M-Pesa account.</li>
                                                        <li style={{ listStyleType: "auto" }}>Click on the "Pay Invoice" button.</li>
                                                        <li style={{ listStyleType: "auto" }}>If paying with your wallet, enter the amount and await your receipt.</li>
                                                        <li style={{ listStyleType: "auto" }}>If paying via M-Pesa, verify your phone number details, enter the amount, and await the M-Pesa prompt. Then, enter your PIN.</li>
                                                        <li style={{ listStyleType: "auto" }}>After a successful payment, await your receipt.</li>
                                                    </ul>
                                                </div>
                                                <div className='me-2' style={{ width: "25%" }}>
                                                    <Button className='border-0' style={{ backgroundColor: '#1F57F8' }}>
                                                        Pay Invoice
                                                    </Button>
                                                </div>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                                <div>
                                                    <h5 className='fw-bold'></h5>
                                                    <span className='w-75 d-flex' style={{ fontSize: "0.9rem", color: "transparent" }} >
                                                    </span>
                                                </div>
                                                <div className='me-2' style={{ width: "45%" }}>
                                                    <p className='d-flex justify-content-between align-items-center'>
                                                        <h6 className='fw-bold'>SUBTOTAL</h6> <span>Ksh 2,000.00</span>
                                                    </p>
                                                    <p className='d-flex justify-content-between align-items-center'>
                                                        <h6 className='fw-bold'>DISCOUNT</h6> <span>Ksh 2.00</span>
                                                    </p>
                                                    <p className='d-flex justify-content-between align-items-center totalButton'>
                                                        <h6 className='fw-bold'>TOTAL</h6> <span>Ksh  1,998.00</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        ) : (
                            <></>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )
}

export default SelectedWalletPlan;
