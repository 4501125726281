import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './SideBar.scss';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router";
import Reports from './../main/Reports';
import { toast } from "react-toastify";
import { useTheme } from './ThemeContext';


function SideBar() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth <= 768) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [windowWidth]);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  // for logout purposes
  const navigate = useNavigate();
  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    toast.success('Successfull logged out !')
    navigate("/login");
  }
  const { theme, toggleTheme } = useTheme();

  return (
    <div fluid className={`p-0 SideBar sidebar-${theme}`}>
      <Navbar className="Logo">
        <button className="btn sidebar-toggle navbar-toggler-icon me-3 ms-3"
          onClick={handleToggleSidebar}

        ></button>
        <Navbar.Brand href="/dashboard">Kodi</Navbar.Brand>
      </Navbar>
      <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
        <Nav defaultActiveKey="/" className="flex-column">
          <Nav.Link as={NavLink} to="/dashboard" exact>
            <i className="bi bi-speedometer2"></i>
            Dashboard
          </Nav.Link>

          <Nav.Link as={NavLink} to="/property">
            <i className="bi bi-houses"></i>
            Properties
          </Nav.Link>
          <Nav.Link as={NavLink} to="/subscription">
            <i className="bi bi-cash-stack"></i>
            Subscription
          </Nav.Link>

          <Nav.Link as={NavLink} to="/wallet">
            <i className="bi bi-wallet"></i>
            Wallet
          </Nav.Link>

          <Nav.Link as={NavLink} to="/utilities">
            <i className="bi bi-house-gear"></i>
            Utilities
          </Nav.Link>

          <Nav.Link as={NavLink} to="/repairs">
            <i className="bi bi-tools"></i>
            Repairs
          </Nav.Link>

          <Nav.Link as={NavLink} to="/reports">
            <i className="bi bi-book"></i>
            Reports
          </Nav.Link>
          <Nav.Link as={NavLink} to="/blog">
                <i class="bi bi-blockquote-right"></i>
                Blogs
              </Nav.Link>

          <Nav.Link as={NavLink} to="/messages">
            <i className="bi bi-chat-text"></i>
            Communicate
          </Nav.Link>

          <Nav.Link as={NavLink} to="/bulk">
            <i className="bi bi-cloud-upload"></i>
            Bulk Uploads
          </Nav.Link>
          <Nav.Link as={NavLink} to="/profile">
            <i className="bi bi-person"></i>
            Profile
          </Nav.Link>

          <Nav.Link onClick={handleLogout}>
            <i className="bi bi-box-arrow-left"></i>
            SignOut
          </Nav.Link>


        </Nav>
      </div>
    </div>
  );
}

export default SideBar;
