import React, { useReducer, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Form, FloatingLabel, Image } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";
import { Card, Accordion } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import './PageAddApartment.scss'

const animatedComponents = makeAnimated();

const steps = [
  "Property Details",
  "Property Image",
  "Units",
  "Units Details",
  "Units Images",
  "Finish",
];

const typeOfHouseChoices = [
  { value: "apartment_units", label: "Apartment Units" },
  { value: "studio", label: "Studio" },
  { value: "bungalow", label: "Bungalow" },
  { value: "bedsitter", label: "Bedsitter" },
  { value: "servant_quarters", label: "Servant Quarters(SQ)" },
  { value: "mansionette", label: "Mansionette" },
  { value: "office", label: "Office" },
  { value: "stall", label: "Stall" },
  { value: "shop", label: "Shop" },
  { value: "1_bedroom", label: "1 Bedroom" },
  { value: "2_bedroom", label: "2 Bedroom" },
  { value: "3_bedroom", label: "3 Bedroom" },
  { value: "4_bedroom", label: "4 Bedroom" },
  { value: "5_bedroom", label: "5 Bedroom" },
  { value: "6_bedroom", label: "6 Bedroom" },
  { value: "7_bedroom", label: "7 Bedroom" },
  { value: "8_bedroom", label: "8 Bedroom" },
];

const initialState = {
  step: 0,
  form1: {
    name: "",
    location: "",
    county: "",
    latitude: "",
    longitude: "",
  },
  form2: {
    selectedOptions: [],
    apartmentId: "",
    apartmentName: "",
  },
  // ... (other form states)
};

const reducer = (state, action) => {
  switch (action.type) {
    case "next":
      return { ...state, step: state.step + 1 };
    case "back":
      return { ...state, step: state.step - 1 };
    case "reset":
      return { ...state, step: 0 };
    case "stagnant":
      return { ...state, step: state };
    default:
      return state;
  }
};

const PageAddApartment = ({ title }) => {
  const navigate = useNavigate()
  const accessToken = localStorage.getItem("authTokens");
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;
  function toCamelCase(str) {
    return str.replace(/_/g, " ");
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const { step } = state;

  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  // the first step functionalities
  const [counties, setCounties] = useState([]);
  const [landlord, setLandlord] = useState([]);

  useEffect(() => {
    // Fetch counties from /lanlord/county endpoint
    serverUrl
      .get(`/landlord/county`)
      .then((res) => {
        setCounties(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    serverUrl
      .get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        setLandlord(res.data.results[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [addedApartments, setAddedApartments] = useState({});
  //   add the apartment data
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // get the data
    const propertyData = {
      name: formData.get("name"),
      location: formData.get("location"),
      county: formData.get("county"),
      latitude: formData.get("latitude"),
      longitude: formData.get("longitude"),
      about: formData.get("about"),

    };

    // post the data of the property
    serverUrl
      .post(`/landlord/apartment/`, propertyData, config)
      .then((res) => {
        setAddedApartments(res.data);

        // post the apartment in the landlord apartment table
        const data = {
          landlord: landlord.id,
          apartment: res.data.id,
        };
        serverUrl
          .post("/landlord/landlord-apartment/", data, config)
          .then((res) => {
            dispatch({ type: "next" });
            toast.success("Succesfull added property details");
          })
          .catch((error) => {
            console.log(error);
            toast.error("Kindly try again an error occured");
          });


      })
      .catch((error) => {
        console.log(error);
        toast.error("Kindly try again an error occured");
      });
  };
  // end of step one execution

  // step two execution process
  const [unitsAdded, setAddedUnits] = useState([]);
  const [newUnitsAdded, setNewAddedUnits] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleDone = () => {
    setIsSelectOpen(false); // Close the select options
  };

  const handleHouseTypeChange = (selectedOptions) => {
    // Filter out options with the word "bedroom" and handle them separately
    const nonBedroomOptions = selectedOptions.filter(
      (option) => !option.label.toLowerCase().includes("bedroom")
    );
    const bedroomOptions = selectedOptions.filter((option) =>
      option.label.toLowerCase().includes("bedroom")
    );

    // Remove the word "bedroom" from bedroom options
    const cleanedBedroomOptions = bedroomOptions.map((option) => ({
      ...option,
      label: option.label.replace(" Bedroom", ""),
    }));

    // Update state with all selected options
    setSelectedOptions(selectedOptions);
  };

  // final steps functions
  const handleStagnatStep = async () => {
    let unitsArray = [];

    try {
      await Promise.all(
        selectedOptions.map(async (option) => {
          let type_of_house = option.value;
          let bedrooms = null;
          let rent_type = "residential";

          if (type_of_house.toLowerCase().includes("bedroom")) {
            bedrooms = parseInt(type_of_house.match(/\d+/)[0]);
            type_of_house = "apartment_units";
          }
          // Check for commercial properties
          if (["office", "shop"].includes(type_of_house.toLowerCase())) {
            rent_type = "commercial";
          }
          // Check for standalone properties
          else if (
            ["bungalow", "mansionette"].includes(type_of_house.toLowerCase())
          ) {
            rent_type = "stand_alone";
          }
          const currentTime = new Date();
          const formattedDate = currentTime.toISOString().split("T")[0];

          const data = {
            house_number: addedApartments.name,
            apartment: parseInt(addedApartments.id),
            due_date: formattedDate,
            type_of_house: type_of_house,
            rent_type: rent_type,
            ...(bedrooms !== null && { bedroom: bedrooms }),
          };

          const res = await serverUrl.post(`/landlord/house/`, data, config);
          unitsArray.push(res.data);
        })
      );
      toast.success("Succesfull added units for the property");
      setNewAddedUnits(unitsArray);
      setSelectedOptions(null)
      // Add CSS style to hide the form after successful save

      document.querySelector('.formData1').style.display = 'none';
      document.querySelector('.formData2').style.display = 'block';


    } catch (error) {
      console.log(error);
    }

  }




  const handleSubmitAddUnit = async (event, final) => {
    event.preventDefault();
    let unitsArray = [];

    try {
      await Promise.all(
        selectedOptions.map(async (option) => {
          let type_of_house = option.value;
          let bedrooms = null;
          let rent_type = "residential";

          if (type_of_house.toLowerCase().includes("bedroom")) {
            bedrooms = parseInt(type_of_house.match(/\d+/)[0]);
            type_of_house = "apartment_units";
          }
          // Check for commercial properties
          if (["office", "shop"].includes(type_of_house.toLowerCase())) {
            rent_type = "commercial";
          }
          // Check for standalone properties
          else if (
            ["bungalow", "mansionette"].includes(type_of_house.toLowerCase())
          ) {
            rent_type = "stand_alone";
          }
          const currentTime = new Date();
          const formattedDate = currentTime.toISOString().split("T")[0];

          const data = {
            house_number: addedApartments.id,
            apartment: parseInt(addedApartments.id),
            due_date: formattedDate,
            type_of_house: type_of_house,
            rent_type: rent_type,
            bedroom: null,
            bathroom: null,
            token_prepaid_account_number: null,
            about: null,
            ...(bedrooms !== null && { bedroom: bedrooms }),
          };

          const res = await serverUrl.post(`/landlord/house/`, data, config);
          unitsArray.push(res.data);
        })
      );
      toast.success("Succesfull added units for the property");
      setAddedUnits(unitsArray);
      setSelectedOptions(null)
      dispatch({ type: "next" });
    }
    catch (error) {
      console.log(error);
    }


  };
  // end of step two

  //   step three execution process
  const [formDataUnits, setFormDataUnits] = useState({
    bedroom: "",
    bathroom: "none",
    rent: "",
    deposit: "",
  });

  // Handle form field changes
  const handleChange = (event, unitId) => {
    const { name, value } = event.target;
    setFormDataUnits((prevFormData) => ({
      ...prevFormData,
      [unitId]: {
        ...prevFormData[unitId],
        [name]: value,
      },
    }));
  };

  const [unitsAddedUpdate, setUnitsAddedUpdate] = useState([]);
  const handleRemoveUnit = (unitId) => {
    // remove the unit from the list
    serverUrl
      .delete(`/landlord/house/${unitId}`, config)
      .then((res) => {
        toast.success("Successfully removed the unit from the property");
        // Update the state to exclude the removed unit
        setAddedUnits((prevUnits) =>
          prevUnits.filter((unit) => unit.id !== unitId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Handle form submission
  const handleUpdateStepTwo = async (Data, unitId) => {
    // Map bathroom values to specific numerical values
    const bathroomMapping = {
      ensuite: 1001,
      master: 1111,
      none: 0,
      ...[...Array(8).keys()].reduce((acc, number) => {
        acc[number + 1] = number + 1;
        return acc;
      }, {}),
    };
    const updatedUnit = {
      id: unitId,
      house_number: Data.name,
      about: Data.about,
      bedroom: Data.bedroom,
      bathroom: bathroomMapping[Data.bathroom],
      rent: Data.rent,
      deposit: Data.deposit,
    };

    const updatedUnits = newUnitsAdded.map((unit) =>
      unit.id === unitId ? updatedUnit : unit
    );

    // If the unit is not in the array, add it
    if (!updatedUnits.find((unit) => unit.id === unitId)) {
      updatedUnits.push(updatedUnit);
    }
    // Set the updated units array in the state
    toast.success("Succesfully updated the unit");
    setUnitsAddedUpdate(updatedUnits);
  };

  const handleUpdate = (e, unitId) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // Map bathroom values to specific numerical values
    const bathroomMapping = {
      ensuite: 1001,
      master: 1111,
      none: 0,
      ...[...Array(8).keys()].reduce((acc, number) => {
        acc[number + 1] = number + 1;
        return acc;
      }, {}),
    };
    const updatedUnit = {
      id: unitId,
      house_number: formData.get("name"),
      about: formData.get("about"),
      bedroom: formData.get("bedroom"),
      bathroom: bathroomMapping[formData.get("bathroom")],
      rent: formData.get("rent"),
      deposit: formData.get("deposit"),
    };

    const updatedUnits = unitsAddedUpdate.map((unit) =>
      unit.id === unitId ? updatedUnit : unit
    );

    // If the unit is not in the array, add it
    if (!updatedUnits.find((unit) => unit.id === unitId)) {
      updatedUnits.push(updatedUnit);
    }

    // Set the updated units array in the state
    toast.success("Succesfully updated the unit");
    setUnitsAddedUpdate(updatedUnits);
  };

  const updateUnitsOnNext = async () => {
    try {
      const unitsToBeUpdated = await Promise.all(
        unitsAddedUpdate.map(async (unit) => {
          const data = {
            bedroom: parseInt(unit.bedroom),
            bathroom: unit.bathroom,
            rent: parseInt(unit.rent),
            deposit: parseInt(unit.deposit),
            house_number: unit.house_number,
          };
          const response = await serverUrl.patch(
            `/landlord/house/${unit.id}/`,
            data,
            config
          );
        })
      );
      setAddedUnits(null)
      toast.success("Succesfully update the units details");
      dispatch({ type: "next" });
    } catch (error) {
      console.error("Error updating units:", error);
      toast.error("Kindly try again an error occured");
    }
  };
  // end of step three


  // step five uploads
  const handleStagnatStepTwo = async () => {
    try {
      const unitsToBeUpdated = await Promise.all(
        unitsAddedUpdate.map(async (unit) => {
          const data = {
            bedroom: parseInt(unit.bedroom),
            bathroom: unit.bathroom,
            rent: parseInt(unit.rent),
            deposit: parseInt(unit.deposit),
            house_number: unit.house_number,
          };
          const response = await serverUrl.patch(
            `/landlord/house/${unit.id}/`,
            data,
            config
          );
        })
      );
      setAddedUnits(null)
      toast.success("Succesfully update the units details");
      document.querySelector('.formData2').style.display = 'none';
      document.querySelector('.formData3').style.display = 'block';

    } catch (error) {
      console.error("Error updating units:", error);
      toast.error("Kindly try again an error occured");
    }

  }

  // submit the updated units images


  // end of step five unit details

  // step four execution
  const [selectedImages, setSelectedImages] = useState([]);
  const onDrop = (acceptedFiles) => {
    // Update selectedImages state with the accepted files
    setSelectedImages((prevImages) => [...prevImages, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Allow only image files
    multiple: true, // Allow multiple files
  });
  const handleRemoveImage = (index) => {
    // Remove the selected image from the state
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  // function to upload the images for units
  async function handleSubmitImage(event) {
    event.preventDefault();

    if (selectedImages.length < 5) {
      toast.error("Please upload a minimum of 5 images.");
      return;
    }

    try {
      toast.info("Uploading images. Please be patient...", {
        autoClose: 60000, 
      });
      const uploadPromises = [
        ...unitsAddedUpdate.flatMap((unit) =>
          selectedImages.map(async (image) => {
            const formDataHouse = new FormData();
            formDataHouse.append("image", image, image.name);
            formDataHouse.append("house", parseInt(unit.id));

            return serverUrl.post(
              "/landlord/house-images/",
              formDataHouse,
              config
            );
          })
        ),
      ];

      // Parallelize requests using Promise.all
      const results = await Promise.all(uploadPromises);

      toast.success("Successfully uploaded the images");
      dispatch({ type: "next" });
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error uploading images");
    }
  }



  // submiting the images of the new images
  async function handleStagnatStepThree() {
    console.log(selectedImages);
    if (selectedImages.length < 5) {
      toast.error("Please upload a minimum of 5 images.");
      return;
    }


    try {
      toast.info("Uploading images. Please be patient...");

      const uploadPromises = [
        ...newUnitsAdded.flatMap((unit) =>
          selectedImages.map(async (image) => {
            const formDataHouse = new FormData();
            formDataHouse.append("image", image, image.name);
            formDataHouse.append("house", parseInt(unit.id));

            return serverUrl.post(
              "/landlord/house-images/",
              formDataHouse,
              config
            );
          })
        ),
      ];

      // Parallelize requests using Promise.all
      const results = await Promise.all(uploadPromises);

      toast.success("Successfully uploaded the images and sucesfully finished the steps");
      dispatch({ type: "reset" });
      navigate('/property')
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error uploading images");
    }
  }


  // check the response of the user
  const [userResponse, setUserResponse] = useState("NoneData")

  const handleChangeResponse = (response) => {
    setUserResponse(response);
  }


  // function to upload property image
  async function handleSubmitPropertyImage(event) {
    event.preventDefault();

    try {
      toast.info("Uploading images. Please be patient...");

      const uploadPromises = [
        ...selectedImages.map(async (image) => {
          const formDataApartment = new FormData();
          formDataApartment.append("image", image, image.name);
          formDataApartment.append("apartment", parseInt(addedApartments.id));

          return serverUrl.post(
            "/landlord/apartment-images/",
            formDataApartment,
            config
          );
        }),
      ];

      // Parallelize requests using Promise.all
      const results = await Promise.all(uploadPromises);

      toast.success("Successfully uploaded the images");
      dispatch({ type: "next" });
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error uploading images");
    }
  }

  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  };

  const removeButtonStyles = {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "red",
    border: "none",
    borderRadius: "0",
    padding: "0px",
    cursor: "pointer",
  };

  // end of step four execution

  // step five execution
  const [description, setDescription] = useState("");

  const handleSubmitDescription = async (event) => {
    // hit the forms submition needed

    event.preventDefault();
    const data = {
      about: description,
    };

    try {
      // Update the apartment details
      await serverUrl.patch(
        `landlord/apartment/${addedApartments.id}/`,
        data,
        config
      );

      // Update the units using Promise.all
      await Promise.all(
        unitsAddedUpdate.map(async (unit) => {
          await serverUrl.patch(`/landlord/house/${unit.id}/`, data, config);
        })
      );

      // All steps completed successfully
      toast.success("Finished all the steps and updated the data");
      dispatch({ type: "reset" });
      navigate('/property')

      // Reset or clear data for the next steps
      setAddedApartments(null);
      setUnitsAddedUpdate([]);
      setDescription("");


    } catch (error) {
      console.log(error);
      // Handle errors if needed
    }
  };


  // end of step five execution

  const handleBack = () => {
    dispatch({ type: "back" });
  };
  const handleNext = () => {
    dispatch({ type: "next" });
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {step === 0 && (
        <Form
          onSubmit={handleSubmit}
          style={{ width: "75%", margin: "0 auto", height: "100vh" }}
        >
          <h2 className="mt-2">Kindly enter property the details</h2>
          <Form.Group className="mb-3" controlId="propertyName">
            <FloatingLabel controlId="propertyName" label="Property Name">
              <Form.Control
                type="text"
                placeholder="Enter property name"
                name="name"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="county">
            <FloatingLabel controlId="location" label="County">
              <Form.Select name="county">
                <option selected disabled>
                  Select County
                </option>
                {counties.map((county) => (
                  <option key={county.id} value={county.name}>
                    {county.county}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="area">
            <FloatingLabel controlId="area" label="Area">
              <Form.Control
                type="text"
                placeholder="Enter Area"
                name="location"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="latitude">
            <FloatingLabel controlId="latitude" label="Latitude">
              <Form.Control
                type="text"
                placeholder="Enter latitude"
                name="latitude"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="longitude">
            <FloatingLabel controlId="longitude" label="Longitude">
              <Form.Control
                type="text"
                placeholder="Enter Longitude"
                name="longitude"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="about">
            <FloatingLabel controlId="about" label="About">
              <Form.Control
                type="text"
                placeholder="Enter About Status"
                name="about"
              />
            </FloatingLabel>
          </Form.Group>

          <Button
            type="submit"
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Next
          </Button>
        </Form>
      )}


      {/* the images for the property */}
      {step === 1 && (
        <div>
          <Form onSubmit={handleSubmitPropertyImage}>
            <h2 className="mt-5">Upload Multiple Images for  {addedApartments.name}</h2>

            <div {...getRootProps()} style={dropzoneStyles}>
              <input {...getInputProps()} />
              <p>Drag & drop some images here, or click to select files</p>
            </div>

            {selectedImages.length > 0 && (
              <div>
                <h4 className="mt-3">Selected Images:</h4>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selectedImages.map((file, index) => (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index + 1}`}
                        rounded
                        thumbnail
                        style={{
                          width: "250px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="mt-1"
                        onClick={() => handleRemoveImage(index)}
                        style={removeButtonStyles}
                      >
                        <i className="bi bi-trash-fill text-white"></i>
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="primary"
            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}

      {/* add the units for that property */}

      {step === 2 && (
        <div>
          <Form
            onSubmit={handleSubmitAddUnit}
            style={{ width: "75%", margin: "0 auto" }}
          >
            <h2 className="mt-5">
              Kindly create the units in {addedApartments.name}
            </h2>
            <Button
              onClick={handleDone}
              sx={{ mt: 2, mb: 2 }}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
            <Select
              closeMenuOnSelect={!isSelectOpen}
              components={animatedComponents}
              isMulti
              defaultValue={`Select Unit Type`}
              options={typeOfHouseChoices}
              value={selectedOptions}
              onChange={handleHouseTypeChange}
              onFocus={() => setIsSelectOpen(true)}
              onBlur={() => setIsSelectOpen(false)}
            />
            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="primary"

            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}

      {step === 3 && (
        <div>
          <h2 className="mt-5">
            Kindly add more details for the units created
          </h2>
          <div style={{ width: "75%", margin: "0 auto" }}>
            <Accordion
              defaultActiveKey={
                unitsAdded.length > 0 ? unitsAdded[0].id.toString() : null
              }
            >
              {unitsAdded.map((units) => (
                <Accordion.Item key={units.id} eventKey={units.id.toString()}>
                  <Accordion.Header>
                    Click this unit to add its details.
                    {units.bedroom && units.bedroom > 0
                      ? ` It is a ${units.bedroom} Bedroom(s).`
                      : ` It is a ${toCamelCase(units.type_of_house)}.`}
                  </Accordion.Header>
                  <Accordion.Body>

                    <Form onSubmit={(e) => handleUpdate(e, units.id)}>
                      <Form.Group className="mb-3" controlId="unitRent">
                        <FloatingLabel
                          controlId="name"
                          label="Name of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter name of unit"
                            name="name"
                            value={formDataUnits[units.id]?.name || ""}
                            onChange={(e) => handleChange(e, units.id)}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="unitRent">
                        <FloatingLabel
                          controlId="rent"
                          label="Rent of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            type="number"
                            placeholder="Enter rent of unit"
                            name="rent"
                            value={formDataUnits[units.id]?.rent || ""}
                            onChange={(e) => handleChange(e, units.id)}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="unitDeposit">
                        <FloatingLabel
                          controlId="deposit"
                          label="Deposit of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            type="number"
                            placeholder="Enter deposit of unit"
                            name="deposit"
                            value={formDataUnits[units.id]?.deposit || ""}
                            onChange={(e) => handleChange(e, units.id)}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="unitBathroom">
                        <FloatingLabel
                          controlId="bathroom"
                          label="Bathroom of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            as="select"
                            value={formDataUnits[units.id]?.bathroom || "none"}
                            onChange={(e) => handleChange(e, units.id)}
                            name="bathroom"
                          >
                            <option value="none">None</option>
                            <option value="ensuite">Ensuite</option>
                            <option value="master">Master Ensuite</option>
                            {[...Array(8).keys()].map((number) => (
                              <option key={number + 1} value={number + 1}>
                                {number + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </FloatingLabel>
                      </Form.Group>

                      {units.bedroom && units.bedroom > 0 ? (
                        // If bedrooms are greater than 0, display the value but no input field
                        <Form.Group
                          className="mb-3 d-none"
                          controlId="unitBedroom"
                        >
                          <FloatingLabel
                            controlId="bedroom"
                            label="Bedroom of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="bedroom"
                              className="d-none"
                              value={units.bedroom}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        // If bedrooms are 0 or not specified, display the input field
                        <Form.Group className="mb-3" controlId="unitBedroom">
                          <FloatingLabel
                            controlId="bedroom"
                            label="Bedroom of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="bedroom"
                              value={formDataUnits[units.id]?.bedroom || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}


                      <Form.Group className="mb-3" controlId="unitRent">
                        <FloatingLabel
                          controlId="about"
                          label="About status of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter about status of unit"
                            name="about"
                            value={formDataUnits[units.id]?.about || ""}
                            onChange={(e) => handleChange(e, units.id)}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <div className="d-flex justify-content-between">
                        <Button type="submit">Update</Button>
                        <Button
                          type="button"
                          onClick={() => handleRemoveUnit(units.id)}
                        >
                          Remove Unit
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <Button
            onClick={handleBack}
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            color="primary"

          >
            Back
          </Button>
          <Button
            onClick={updateUnitsOnNext}
            type="submit"
            sx={{ mt: 2 }}
            variant="contained"
            color="success"
          >
            Next
          </Button>
        </div>
      )}

      {step === 4 && (
        <div>
          <Form onSubmit={handleSubmitImage}>
            <h2 className="mt-5">Upload Multiple Images for units added (minimum is 5)</h2>

            <div {...getRootProps()} style={dropzoneStyles}>
              <input {...getInputProps()} />
              <p>Drag & drop some images here, or click to select files</p>
            </div>

            {selectedImages.length > 0 && (
              <div>
                <h4 className="mt-3">Selected Images:</h4>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selectedImages.map((file, index) => (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index + 1}`}
                        rounded
                        thumbnail
                        style={{
                          width: "250px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="mt-1"
                        onClick={() => handleRemoveImage(index)}
                        style={removeButtonStyles}
                      >
                        <i className="bi bi-trash-fill text-white"></i>
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="primary"
            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}

      {step === 5 && (
        <div>
          {/* open questionare for the user */}




          <Form onSubmit={handleSubmitDescription}>
            {userResponse === "NoneData" ?
              <div>
                <h2 className="mt-4">Do you want to add other units for  {addedApartments.name} </h2>
                <div className="d-flex  w-25 mx-auto" style={{ placeContent: "space-evenly" }}>
                  <Button
                    type="submit"
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="success"

                  >
                    No, Finish
                  </Button>
                  <Button
                    type="button"
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleChangeResponse('Yes')}
                  >
                    Yes, Add New
                  </Button>


                </div>
              </div>
              :
              <></>}



            {/* let them select the units that the want to add in bulk */}
            {userResponse === 'Yes' ?
              <>
                <Form
                  onSubmit={handleStagnatStep}
                  style={{ width: "75%", margin: "0 auto" }}
                  className="formData1 mt-4"
                >
                  <h3>Add other units for {addedApartments.name}</h3>
                  <Button
                    sx={{ mt: 2, mb: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleStagnatStep}

                  >
                    Save
                  </Button>

                  <Select
                    closeMenuOnSelect={!isSelectOpen}
                    components={animatedComponents}
                    isMulti
                    defaultValue={`Select Unit Type`}
                    options={typeOfHouseChoices}
                    value={selectedOptions}
                    onChange={handleHouseTypeChange}
                    onFocus={() => setIsSelectOpen(true)}
                    onBlur={() => setIsSelectOpen(false)}
                  />

                </Form>

                {/* form data two */}

                <div style={{ width: "75%", margin: "0 auto", display: "none" }} className="formData2">
                  {newUnitsAdded && (
                    <Accordion
                      defaultActiveKey={
                        newUnitsAdded && newUnitsAdded.length > 0 ? newUnitsAdded[0].id.toString() : null
                      }
                    >

                      {newUnitsAdded && (
                        <h4 className="mt-5">Update the details of the newely added units </h4>

                      )}

                      {newUnitsAdded && newUnitsAdded.map((units) => (
                        <>
                          <Accordion.Item key={units.id} eventKey={units.id.toString()}>
                            <Accordion.Header>
                              Click this unit to add its details.
                              {units.bedroom && units.bedroom > 0
                                ? ` It is a ${units.bedroom} Bedroom(s).`
                                : ` It is a ${toCamelCase(units.type_of_house)}.`}
                            </Accordion.Header>
                            <Accordion.Body>

                              <Form >
                                <Form.Group className="mb-3" controlId="unitRent">
                                  <FloatingLabel
                                    controlId="name"
                                    label="Name of Unit"
                                    style={{ width: "80vw" }}
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter name of unit"
                                      name="name"
                                      value={formDataUnits[units.id]?.name || ""}
                                      onChange={(e) => handleChange(e, units.id)}
                                    />
                                  </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="unitRent">
                                  <FloatingLabel
                                    controlId="rent"
                                    label="Rent of Unit"
                                    style={{ width: "80vw" }}
                                  >
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter rent of unit"
                                      name="rent"
                                      value={formDataUnits[units.id]?.rent || ""}
                                      onChange={(e) => handleChange(e, units.id)}
                                    />
                                  </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="unitDeposit">
                                  <FloatingLabel
                                    controlId="deposit"
                                    label="Deposit of Unit"
                                    style={{ width: "80vw" }}
                                  >
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter deposit of unit"
                                      name="deposit"
                                      value={formDataUnits[units.id]?.deposit || ""}
                                      onChange={(e) => handleChange(e, units.id)}
                                    />
                                  </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="unitBathroom">
                                  <FloatingLabel
                                    controlId="bathroom"
                                    label="Bathroom of Unit"
                                    style={{ width: "80vw" }}
                                  >
                                    <Form.Control
                                      as="select"
                                      value={formDataUnits[units.id]?.bathroom || "none"}
                                      onChange={(e) => handleChange(e, units.id)}
                                      name="bathroom"
                                    >
                                      <option value="none">None</option>
                                      <option value="ensuite">Ensuite</option>
                                      <option value="master">Master Ensuite</option>
                                      {[...Array(8).keys()].map((number) => (
                                        <option key={number + 1} value={number + 1}>
                                          {number + 1}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </FloatingLabel>
                                </Form.Group>

                                {units.bedroom && units.bedroom > 0 ? (
                                  // If bedrooms are greater than 0, display the value but no input field
                                  <Form.Group
                                    className="mb-3 d-none"
                                    controlId="unitBedroom"
                                  >
                                    <FloatingLabel
                                      controlId="bedroom"
                                      label="Bedroom of Unit"
                                      style={{ width: "80vw" }}
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Enter deposit of unit"
                                        name="bedroom"
                                        className="d-none"
                                        value={units.bedroom}
                                        onChange={(e) => handleChange(e, units.id)}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                ) : (
                                  // If bedrooms are 0 or not specified, display the input field
                                  <Form.Group className="mb-3" controlId="unitBedroom">
                                    <FloatingLabel
                                      controlId="bedroom"
                                      label="Bedroom of Unit"
                                      style={{ width: "80vw" }}
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Enter deposit of unit"
                                        name="bedroom"
                                        value={formDataUnits[units.id]?.bedroom || ""}
                                        onChange={(e) => handleChange(e, units.id)}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                )}


                                <Form.Group className="mb-3" controlId="unitRent">
                                  <FloatingLabel
                                    controlId="about"
                                    label="About status of Unit"
                                    style={{ width: "80vw" }}
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter about status of unit"
                                      name="about"
                                      value={formDataUnits[units.id]?.about || ""}
                                      onChange={(e) => handleChange(e, units.id)}
                                    />
                                  </FloatingLabel>
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                  <Button type="button"
                                    onClick={() => handleUpdateStepTwo(formDataUnits[units.id], units.id)}>
                                    Update</Button>

                                </div>
                              </Form>
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      ))}
                    </Accordion>
                  )}
                  <Button
                    onClick={handleStagnatStepTwo}
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="success"
                  >
                    Save Changes
                  </Button>
                </div>



                {/* update the images for the selected units  */}
                <div>
                  {newUnitsAdded && (
                    <Form onSubmit={handleStagnatStepThree} style={{ display: "none" }} className='formData3'>
                      <h2 className="mt-5 " >Upload Multiple Images for units added (minimum is 5)</h2>

                      <div {...getRootProps()} style={dropzoneStyles}>
                        <input {...getInputProps()} />
                        <p>Drag & drop some images here, or click to select files</p>
                      </div>

                      {selectedImages.length > 0 && (
                        <div>
                          <h4 className="mt-3">Selected Images:</h4>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selectedImages.map((file, index) => (
                              <div
                                key={index}
                                style={{ margin: "10px", position: "relative" }}
                              >
                                <Image
                                  src={URL.createObjectURL(file)}
                                  alt={`Image ${index + 1}`}
                                  rounded
                                  thumbnail
                                  style={{
                                    width: "250px",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mt-1"
                                  onClick={() => handleRemoveImage(index)}
                                  style={removeButtonStyles}
                                >
                                  <i className="bi bi-trash-fill text-white"></i>
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <Button
                        type="button"
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="success"
                        onClick={handleStagnatStepThree}
                      >
                        Save Images
                      </Button>
                    </Form>

                  )}
                </div>


              </>

              :
              <></>}

          </Form>




        </div>
      )}
    </Box>
  );
};

export default PageAddApartment;
