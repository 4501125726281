import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Routes importing
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { useEffect } from "react";


// Importing Pages Auth
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";

// Importing main pages
import Home from "./pages/main/Home";
import Statutory from './pages/pages/Statutory';
// The navlink pages
import Landing from "./pages/main/Landing";
import Configure from './pages/main/Configure';
import Utility from './pages/main/Utilities';
import ProfileOld from './pages/main/Profile';
import ReportsOld from './pages/main/Reports';

// other pages
import AddHouse from './pages/pages/AddHouse'
import AddProperty from './pages/pages/AddProperty';
import ApartmentImage from './pages/pages/ApartmentImage';
import HouseImage from "./pages/pages/HouseImage";

// new dashboard
import Dashboard from "./pages/newDashboard/Dashboard";
import Property from './pages/newDashboard/Property';
import SelectedProperty from './pages/newDashboard/SelectedProperty';
import HomeAddProperty from './pages/pages/HomeAddProperty';
import SelectedHouse from './pages/newDashboard/SelectedHouse';
import Utilities from "./pages/newDashboard/Utilities";
import SelectedPropertyUtilities from "./pages/newDashboard/SelectedPropertyUtilities";
import Profile from "./pages/newDashboard/Profile";
import MyTable from './pages/newDashboard/MyTable';
import PdfR from './pages/newDashboard/PdfR';
import Account from './pages/newDashboard/Account';

// reports
import RentPaymentReport from './pages/newDashboard/reports/RentPaymentReport';
import UtilitiesReport from './pages/newDashboard/reports/UtilitiesReport';
import OccupancyReport from './pages/newDashboard/reports/OccupancyReport';
import IncomeReport from './pages/newDashboard/reports/IncomeReport';
import OwnersReport from './pages/newDashboard/reports/OwnersReport';
import Reports from './pages/newDashboard/reports/Reports';
import SelectedApartmentReport from './pages/newDashboard/reports/SelectedApartmentReport';
import TenantReports from './pages/newDashboard/reports/TenantReports';
import IssueReport from "./pages/newDashboard/reports/IssueReport";
import HistoryReport from "./pages/newDashboard/reports/HistoryReport";
import Table from "./pages/pages/Table";
import Repairs from './pages/newDashboard/Repairs';
import SelectedApartmentRepairs from './pages/newDashboard/SelectedApartmentRepairs';
import PageAddApartment from "./pages/pages/PageAddApartment";
import AddTenant from "./pages/newDashboard/AddTenant";
import SelectedHouseImage from "./pages/newDashboard/SelectedHouseImage";
import SelectedPropertyImage from "./pages/newDashboard/SelectedPropertyImage";
import ImageResize from "./pages/newDashboard/ImageResize";
import Messages from "./pages/newDashboard/Messages";
import BulkUploads from "./pages/newDashboard/BulkUploads";
import Wallet from "./pages/newDashboard/Wallet";
import WalletLogin from "./pages/newDashboard/WalletLogin";
import MainWallet from "./pages/newDashboard/MainWallet";
import WalletOtp from "./pages/newDashboard/WalletOtp";
import FailedReconciliation from "./pages/newDashboard/reports/FailedReconciliation";
import OverflowReport from "./pages/newDashboard/reports/OverflowReport";
import { MessagesProvider } from './pages/newDashboard/MessageContext';
import MessagesOutbox from './pages/newDashboard/MessagesOutbox';
import ForgotPasword from "./pages/auth/ForgotPasword";
import WalletProperty from "./pages/newDashboard/WalletProperty";
import SubscriptionDashboard from "./pages/newDashboard/SubscriptionDashboard";

import { useTheme } from '../src/pages/newDashboard/ThemeContext';
import SelectedWalletPlan from "./pages/newDashboard/SelectedWalletPlan";
import NewPlan from "./pages/newDashboard/NewPlan";
import MakeSubscriptionPayment from "./pages/newDashboard/MakeSubscriptionPayment";
import Blogs from "./pages/newDashboard/Blog";
import BlogsAdd from "./pages/newDashboard/BlogAdd";
import SelectedBlog from "./pages/newDashboard/SelectedBlog";
import SelectedBlogImage from "./pages/newDashboard/SelectedBlogImage";
import AddRoomImage from "./pages/pages/AddRoomImage";
import RoomAdd from "./pages/newDashboard/RoomAdd";
import BlogEdit from "./pages/newDashboard/BlogEdit";



function App() {
  const isAuthenticated = localStorage.getItem('authTokens') !== null;

  const { theme, toggleTheme } = useTheme();

  return (

    <div className={`App app_${theme}`}>
      <ToastContainer />


      <HashRouter>
        <Routes>
          <Route exact path="/">
            {isAuthenticated ? <Route Component={Dashboard} path="/" /> : <Route Component={Home} exact path="/" />}
          </Route>
      

          <>
            <Route path="/home" Component={Dashboard} />
            <Route path="/forgot-password" Component={ForgotPasword} />

            <Route path="/statutory" Component={Statutory} />
            <Route path="/add-house" Component={AddHouse} />
            <Route path="/add-property" Component={AddProperty} />
            <Route path="/add-apartment" Component={PageAddApartment} />
            <Route path="/Apartment-image" Component={ApartmentImage} />
            <Route path="/house-image" Component={HouseImage} />
            <Route path='/blog' Component={Blogs} />
            <Route path="/add-blog" Component={BlogsAdd} />

            <Route path='/selected-blog/:id' Component={SelectedBlog} />
            <Route path='/selected-blog-image/:id' Component={SelectedBlogImage} />

            {/* navlink pages */}
            <Route path="/property" Component={Property} />
            <Route path="/bulk" Component={BulkUploads} />
            <Route path="/selected-property/:id" Component={SelectedProperty} />
            <Route path="/selected-property-image/:id" Component={SelectedPropertyImage} />
            <Route path="/configure" Component={Configure} />
            <Route path="/utilities" Component={Utilities} />
            <Route path="/profile" Component={Profile} />
            <Route path="/reports" Component={Reports} />
            <Route path="/messages" Component={Messages} />
            <Route path="/repairs" Component={Repairs} />
            <Route path="/selected-apartment-repairs/:id" Component={SelectedApartmentRepairs} />
            <Route path='/home-property' Component={HomeAddProperty} />
            <Route path='/selected-house/:id' Component={SelectedHouse} />
            <Route path='/selected-house-image/:id' Component={SelectedHouseImage} />
            <Route path='/selected-property-utility/:id' Component={SelectedPropertyUtilities} />
            <Route path='/selected-property-report/:id' Component={SelectedApartmentReport} />
            <Route path='/subscription' Component={SubscriptionDashboard} />
            <Route path='/wallet-data/:id' Component={SelectedWalletPlan} />
            <Route path='/new-plan/:id' Component={NewPlan} />
            <Route path="/room-image/:id" Component={AddRoomImage} />
            <Route path="/add-room/:id" Component={RoomAdd} />
            <Route path="/edit-blog/:id" Component={BlogEdit} />
            
            {/* reports */}
            <Route path='/occupancy-report/:id' Component={OccupancyReport} />
            <Route path='/income-report/:id' Component={IncomeReport} />
            <Route path='/payment-report/:id' Component={RentPaymentReport} />
            <Route path='/utility-report/:id' Component={UtilitiesReport} />
            <Route path='/failed-report/:id' Component={FailedReconciliation} />
            <Route path='/overpayment-report/:id' Component={OverflowReport} />
            <Route path='/owner-report/:id' Component={OwnersReport} />
            <Route path='/issue-report/:id' Component={IssueReport} />
            <Route path='/make-payment/:id' Component={MakeSubscriptionPayment} />



            <Route path='/tenant-report/:id' Component={TenantReports} />
            <Route path='/issue-report/:id' Component={IssueReport} />
            <Route path='/history-report/:id' Component={HistoryReport} />
            <Route path='/wallet' Component={MainWallet} />
            <Route path='/property-wallet/:id' Component={Wallet} />
            <Route path='/wallet-otp' Component={WalletOtp} />
            <Route path='/wallet-property/:id' Component={WalletProperty} />




            <Route path='/wallet-login' Component={WalletLogin} />


            <Route path='/table' Component={Table} />
            <Route path='pdf' Component={PdfR} />
            <Route path='/update-profile' Component={ImageResize} />

            <Route path="add-tenant/:id/:housetenant" Component={AddTenant} />

            <Route path='/account/:id' Component={Account} />
            <Route path='/my-table' Component={MyTable} />
            {/* auth pages */}
            <Route path="/login" Component={Login} />
            <Route path="/register" Component={Register} />

            <Route path="/dashboard" Component={Dashboard} />
            <Route path="/statutory" Component={Statutory} />

            <Route path="/add-house" Component={AddHouse} />
            <Route path="/add-property" Component={AddProperty} />
            <Route path="/Apartment-image" Component={ApartmentImage} />
            <Route path="/house-image" Component={HouseImage} />
            <Route path="*" element={<Navigate to="/" />} />
          </>


        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
