import React, { useState, useEffect } from 'react'
import './RentPaymentReport.scss'
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl, Image, Modal, Col, Row } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Header from '../Header';
import SideBar from '../SideBar';
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function RentPaymentReport() {
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  const monthNames = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];
  const [isLoading, setIsLoading] = useState(false);

  // getting the id and finding the apparment details
  const { id } = useParams();


  // get the default tenants


  // houses within the apartment
  const [selectedHouses, setSelectedHouses] = useState([])
  const [selectedHousesFilter, setSelectedHousesFilter] = useState([])

  const [houseSetTenant, setHouseSetTenant] = useState([])
  const houseTenant = {}
  const [houseSetPayment, setHouseSetPayment] = useState([])
  const housePaymentStatus = {}
  const [apartmentPaymentPercent, setApartmentPaymentPercent] = useState("")


  // conts used in filtering
  const [showModal, setShowModal] = useState(false)
  const [showModalPayment, setShowModalPayment] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedYearMonthlyPayment, setSelectedYearMonthlyPayment] = useState([]);
  const [selectedYearMonthlyPercentage, setSelectedYearMonthlyPercentage] = useState([]);

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;


  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  // Get the current month automatically
  const currentDate = new Date();
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // get the months till today
  const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
    const month = new Date(currentDate.getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;

  const years = Array.from({ length: 21 }, (_, i) => startYear - i);


  // display 12 churches per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);

  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };

  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);


  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("")
  const [yearFilterActive, setYearFilterActive] = useState("")

  const handleOpenModalPayment = () => {
    setShowModalPayment(true)
  }
  const handleCloseModalPayment = () => {
    setShowModalPayment(false)
  }
  const handleCloseModal = () => {

    setShowModal(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    if (yearFilterActive) {
      localStorage.removeItem('yearPayment');
    }
    localStorage.setItem("monthPayment", month);
    setShowModal(false);
    window.location.reload();

  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
    if (monthFilterActive) {
      localStorage.removeItem('monthPayment');
    }

    localStorage.setItem("yearPayment", year);
    setShowModal(false);
    window.location.reload();

  };

  const [landlordApartment, setLandlordApartment] = useState([])
  const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);
  const [selectedHouseId, setSelectedHouseId] = useState(null);

  const [apartmentHouse, setApartmentHouse] = useState([]);

  // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
  useEffect(() => {

    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => res.data.results)
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);
            // getting the houses in the apartment
            const houseCountPromises = apartments.map((apartment) => {
              return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, houses: [] };
                });
            });

            // set count of the number of houses that are in that apartment
            Promise.all(houseCountPromises)
              .then((results) => {
                // object that gets the number of apartments
                const houseCountObj = {};
                results.forEach(({ apartmentId, houses }) => {
                  houseCountObj[apartmentId] = houses;
                });
                setSelectedHousesFilter(houseCountObj);
              })
              .catch((error) => {
                console.log(error);
              });

          })
          .catch((error) => {
            console.log(error);

          });
      })
      .catch((error) => {
        console.log(error);
      });

    // get if the filter is set
    const setMonthFilter = localStorage.getItem("monthPayment")
    setMonthFilterActive(setMonthFilter)

    // get if the filter is set for year
    const setYearFilter = localStorage.getItem("yearPayment")
    setYearFilterActive(setYearFilter)

    // check the filtered data
    let monthSelect = "";
    let yearSelect = "";

    if (setMonthFilter) {
      monthSelect = setMonthFilter
    }
    else {
      monthSelect = currentMonth
    }

    if (setYearFilter) {
      yearSelect = setYearFilter
      // on the situation that the year is selected get to store all the data of the houses and % paid in arrays
      const paymentYearlyMonthly = []
      const percentageYearlyMonthly = []

      // get the status and percentage per selected year
      serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&year=${yearSelect}`)
        .then((res) => {
          setSelectedYearMonthlyPercentage(res.data.paid_percentage)

        })
        .catch((error) => {
          console.log(error)
        })
      // we will have to loop through all the months but with the selected year
      Promise.all(
        months.map((month) =>
          serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&month=${month}&year=${yearSelect}`, config)
            .then((res) => {
              const housesMonthlyYearly = res.data.payment_list
              const housesMonthlyYearlyPercentage = res.data.paid_percentage

              paymentYearlyMonthly.push(housesMonthlyYearly)
              percentageYearlyMonthly.push(housesMonthlyYearlyPercentage)

            })
            .catch((error) => {
              console.log(error);
            })
        )
      )
        .then(() => {
          // get the total of the percentages 
          const combinedDataPercentage = [].concat.apply([], percentageYearlyMonthly);
          const totalPercentage = combinedDataPercentage.reduce((acc, value) => acc + value, 0);
          const yearlyPercentage = Math.round(totalPercentage / 12);


          // Combine the arrays for the houses
          const combinedData = [].concat.apply([], paymentYearlyMonthly);

          setSelectedYearMonthlyPayment(combinedData)
          // setSelectedYearMonthlyPercentage(yearlyPercentage)
        })
        .catch((error) => {
          console.log(error);
        })

    }
    else {
      yearSelect = currentYear
    }

    // get the percentage of the total ampount paid in the apartment

    serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&month=${monthSelect}&year=${yearSelect}`, config)
      .then((res) => {
        setApartmentPaymentPercent(res.data.paid_percentage)
        setSelectedHouses(res.data.payment_list)

      })
      .catch((error) => {
        console.log(error);

      })
  }, []);

  const uniquePaymentMonth = new Set();
  const uniqueMonth = selectedYearMonthlyPayment.filter((invoice_month) => {
    if (!uniquePaymentMonth.has(invoice_month.invoice_month)) {
      uniquePaymentMonth.add(invoice_month.invoice_month);
      return true;
    }
    return false;
  });
  // Create a Set to store unique users
  const uniqueUsers = new Set();
  // Filter and get unique users based on some criteria
  const uniqueUserList = selectedYearMonthlyPayment.filter((user) => {
    // Define your criteria for uniqueness here
    const criteria = `${user.invoice_month}_${user.tenant_name}`;

    if (!uniqueUsers.has(criteria)) {
      uniqueUsers.add(criteria);
      return true;
    }
    return false;
  });


  // Now, `uniqueUserList` contains unique users based on your criteria

  function handleDownload() {
    try {
      setIsLoading(true);
      // check the filtered data

      // get if the filter is set
      const setMonthFilter = localStorage.getItem("monthPayment")
      setMonthFilterActive(setMonthFilter)

      // get if the filter is set for year
      const setYearFilter = localStorage.getItem("yearPayment")
      setYearFilterActive(setYearFilter)

      let monthSelect = "";
      let yearSelect = "";

      if (setMonthFilter) {
        monthSelect = setMonthFilter
      }
      else {
        monthSelect = currentMonth
      }

      if (setYearFilter) {
        yearSelect = setYearFilter
        const paymentYearlyMonthly = []
        const percentageYearlyMonthly = []
        serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&month=${monthSelect}&year=${yearSelect}&download=true`, config)
          .then((res) => {
            // create a download url for the pdf and then automatically download the pdf
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'payment_report.pdf');
            document.body.appendChild(link);
            link.click();
            toast.success('Successfully Downloaded Your Payment Report');
            setIsLoading(false);

          })
          .catch((error) => {
            console.log(error);
          })



      }
      else {
        yearSelect = currentYear
        // get the percentage of the total ampount paid in the apartment
        serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&month=${monthSelect}&year=${yearSelect}&download=true`, config)
          .then((res) => {
            // create a download url for the pdf and then automatically download the pdf
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'payment_report.pdf');
            document.body.appendChild(link);
            link.click();
            toast.success('Successfully Downloaded Payment Report');
            setIsLoading(false);

          })
          .catch((error) => {
            console.log(error);

          })
      }


    } catch (error) { }
  }

  // print the pdf for the selected year
  function handleDownloadYear() {
    try {
      // check the filtered data

      // get if the filter is set
      const setMonthFilter = localStorage.getItem("monthPayment")
      setMonthFilterActive(setMonthFilter)

      // get if the filter is set for year
      const setYearFilter = localStorage.getItem("yearPayment")
      setYearFilterActive(setYearFilter)

      let monthSelect = "";
      let yearSelect = "";

      if (setMonthFilter) {
        monthSelect = setMonthFilter
      }
      else {
        monthSelect = currentMonth
      }

      if (setYearFilter) {

        yearSelect = setYearFilter
        const paymentYearlyMonthly = []
        const percentageYearlyMonthly = []
        serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&year=${yearSelect}&download=true`, config)
          .then((res) => {
            // create a download url for the pdf and then automatically download the pdf
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'payment_report.pdf');
            document.body.appendChild(link);
            link.click();
            toast.success('Successfully Downloaded Your Payment Report');

          })
          .catch((error) => {
            console.log(error);
          })



      }
      else {
        yearSelect = currentYear
        // get the percentage of the total ampount paid in the apartment
        serverUrl.get(`/tenant/generate-apartment-payment-report/?apartment_id=${id}&year=${yearSelect}&download=true`, config)
          .then((res) => {
            // create a download url for the pdf and then automatically download the pdf
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'payment_report.pdf');
            document.body.appendChild(link);
            link.click();
            toast.success('Successfully Downloaded Payment Report');

          })
          .catch((error) => {
            console.log(error);

          })
      }


    } catch (error) { }
  }

  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = yearFilterActive ? uniqueUserList : selectedHouses.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('monthPayment');
    localStorage.removeItem('yearPayment');
    toast.success('Successfully Reset The Payment Report Filter!');
    window.location.reload()
  };

  const exportCSV = () => {
    const csvData = [
      ['Tenant', 'House', 'Status', 'Date Paid', 'Paid'],
      ...selectedHouses.map((item) => [item.tenant_name, item.house_number, item.status, item.date, item.paid]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'payment_report.csv');
  };
  const [selectedUtilities, setSelectedUtilities] = useState([])
  const [selectedApartmentId, setSelectedApartmentId] = useState("");

  function handleApartmentSelect(selectedApartmentId) {
    setSelectedApartmentId(selectedApartmentId)
    // get the houses to be listed when apartment is selected
    serverUrl
      .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
      .then((res) => {
        // Filter houses with occupancy status "vacant"
        const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

        // Set the filtered vacant houses in the state
        setApartmentHouse(vacantHouses);
      })
      .catch((error) => {
        // Handle the error, e.g., display an error message to the user
        console.log("Error fetching apartment data:", error);
        // You can also update state to indicate the error, if needed
      });

    // get the utilities to be displayed for that apartment
    serverUrl.get(`/landlord/expense/?apartment_id=${selectedApartmentId}`, config)
      .then((res) => {
        setSelectedUtilities(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  // handle when the house is selected
  const [houseSelectedId, setHouseSelectedId] = useState("");
  const [invoices, setInvoices] = useState([])
  const [invoiceSelectedId, setInvoiceSelectedId] = useState("");
  const [houseSelectedName, setHouseSelectedName] = useState("")


  function handleHouseSelect(selectedHouseid) {
    setHouseSelectedId(selectedHouseid)

    // Find the selected house object based on its ID
    const selectedHouse = apartmentHouse.find((house) => house.house_id === parseInt(selectedHouseid));

    // Log the ID and house_number
    if (selectedHouse) {
      setHouseSelectedName(selectedHouse.house)
    }


    serverUrl.get(`/landlord/tenant-invoice/?house_id=${selectedHouseid}&reference=${selectedCategory}`, config)
      .then((res) => {
        // Filter invoices with status not equal to "paid"
        const unpaidInvoices = res.data.results.filter(invoice => invoice.status !== "paid");
        setInvoices(unpaidInvoices);
      })
      .catch((error) => {
        console.log(error);
      });

    // console.log('house selected', selectedHouseid)
  }
  // form data for tenant details ratings
  const [formDataPayment, setFormDataPayment] = useState({
    apartment: "",
    house_number: "",
    category: '',
    utility_type: '',
    source: '',
    amount: '',
    paid_date: '',
    phone_number: ''
  });
  // onchange functionality on rating tenant
  const handleChangePayment = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (name === "phone_number") {
      // Check if the entered number starts with '+254' and has at most 12 digits
      if (!value.startsWith("254") || value.length > 13 || isNaN(value.slice(4))) {
        errorMessage = "Invalid phone number format. Please enter a valid number starting with '254' and followed by up to 9 digits.";
      }
    }

    setFormDataPayment({
      ...formDataPayment,
      [name]: value,
      errorMessage: errorMessage
    });

  }

  // handle when the utility is selected
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedUtility, setSelectedUtility] = useState("");

  const handleCategorySelect = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedUtility("");
  };

  // handle when the type of payment is selected
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handlePaymentSelect = (e) => {
    setSelectedMethod(e.target.value);
    setSelectedPaymentMethod("");
  };


  // the invoice selected
  function handleInvoiceSelect(selectedInvoiceid) {
    setInvoiceSelectedId(selectedInvoiceid)

  }


  // save the data to the backend on the payment
  function handleSavePayment(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // Remove hyphen from field name
    const getCurrentTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };
    // Add current time (hours, minutes, seconds)
    const currentTime = getCurrentTime();

    // const combinedValue = `${formatedDate}${currentTime}`;
    const tenant = apartmentHouse.filter((house) => house.house === houseSelectedName && house.apartment === selectedApartmentId)
    const fullName = tenant[0].tenant;
    const firstName = fullName.split(' ')[0];

    const dataForPayment = {
      transcation_type: selectedMethod,
      transcation_id: selectedMethod + houseSelectedName,
      bill_ref_number: houseSelectedName,
      apartment: selectedApartmentId,
      transcation_time: currentTime,
      transcation_amount: formDataPayment.amount,
      phone_number: formDataPayment.phone_number,
      first_name: firstName,
    };


    // post the data to the ctob
    serverUrl.post(`/landlord/c2btranscation/`, dataForPayment, config)
      .then((res) => {
        setShowModalPayment(false)
        window.location.reload()

        // after the data post it to the reconciliation
        serverUrl.get(`/landlord/reconcile_transactions/?c2btransaction_id=${res.data.id}&house_id=${houseSelectedId}&payment_category=${selectedCategory}&expense_id=${selectedUtility}&invoice_month=${invoiceSelectedId}`, config)
          .then((res) => {
          })
          .catch((error) => {
            console.log(error)
          })
        toast.success('Succesfully added the payment!')

      })
      .catch((error) => {
        console.log(error)
      })

  }
  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='rentReport'>


        {/* list of the tenants of the apartment and payment details */}
        <div className='tasks ' >
          {/* the percentage of the amount paid */}
          <div className='d-flex justify-content-between'>
            <h2 style={{ color: "#f39c12" }} >payment report</h2>
            {/* selectedYearMonthlyPercentage */}
            {yearFilterActive ?
              selectedYearMonthlyPercentage > 0 ?
                <>
                  {selectedYearMonthlyPercentage < 33 ? (
                    <Button className='btn btn-sm bg-danger many'>{selectedYearMonthlyPercentage} % Paid</Button>

                  ) : selectedYearMonthlyPercentage < 67 ? (

                    <Button className='btn btn-sm bg-warning many'>{selectedYearMonthlyPercentage} % Paid</Button>
                  ) : (
                    <Button className='btn btn-sm bg-success many'>{selectedYearMonthlyPercentage} % Paid</Button>
                  )}
                </>
                :
                <td><span className={`badge bg-danger p-2`}>0% Paid</span></td>

              :
              apartmentPaymentPercent > 0 ?
                <>
                  {apartmentPaymentPercent < 33 ? (
                    <Button className='btn btn-sm bg-danger many'>{apartmentPaymentPercent} % Paid</Button>

                  ) : apartmentPaymentPercent < 67 ? (

                    <Button className='btn btn-sm bg-warning many'>{apartmentPaymentPercent} % Paid</Button>
                  ) : (
                    <Button className='btn btn-sm bg-success many'>{apartmentPaymentPercent} % Paid</Button>
                  )}
                </>
                :
                <td><span className={`badge bg-danger p-2`}>0% Paid</span></td>
            }


          </div>


          {/* the top part with the filters */}

          <div className='d-flex justify-content-between'>
            <Form.Select size='sm' className='formCal' onChange={handleSelectChange} style={{ width: "20%" }}>
              <option selected disabled>Filter Report By:</option>
              <option>Monthly</option>
              <option>Yearly</option>
            </Form.Select>

            {monthFilterActive ?
              <p className='filterText'>Payment Report For {monthFilterActive} </p>
              : yearFilterActive ?
                <p className='filterText'>Payment Report For {yearFilterActive}</p>
                :
                <p className='filterText' style={{ marginRight: "31rem" }}>Payment Report For {currentMonth}</p>

            }

            {monthFilterActive || yearFilterActive ?
              <Button className='text-white filterButton' onClick={handleReset}>Reset Filter</Button>

              :
              <></>}
          </div>

          {/* modal to be displayed */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='bg-primary text-white' closeButton>
              <Modal.Title>Filter By {selectedOption}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedOption === currentMonth ?
                (
                  <>
                    <p>{currentMonth}</p>

                  </>
                )
                : selectedOption === "Monthly" ?
                  (
                    <>
                      <Form>
                        <Row className="flex-wrap justify-content-between">
                          {months.map((month) => (
                            <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                              <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                                {month}
                              </Button>
                            </Col>
                          ))}
                        </Row>

                      </Form>
                    </>

                  )
                  : selectedOption === "Yearly" ?
                    (
                      <>
                        <Form>
                          <Row className="flex-wrap justify-content-between">
                            {displayedYears.map((year) => (
                              <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                                <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleYearButtonClick(year)}>
                                  {year}
                                </Button>
                              </Col>
                            ))}
                          </Row>
                          <div className="mt-3 d-flex justify-content-center">
                            {currentYearPage > 0 && (
                              <Button variant="outline-primary" onClick={handlePrevYearPage}>
                                Previous
                              </Button>
                            )}
                            {currentYearPage < totalPagesYear - 1 && (
                              <Button variant="outline-primary" onClick={handleNextYearPage}>
                                Next
                              </Button>
                            )}
                          </div>
                        </Form>
                      </>
                    ) :
                    (
                      <>
                        <p>Date</p>
                      </>
                    )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='d-flex justify-content-between'>

            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              {yearFilterActive ?
                <Button className='btn btn-danger text-white me-3' onClick={handleDownloadYear} >PDF <i className='bi bi-file-pdf-fill'></i></Button>
                :
                <Button className='btn btn-danger text-white me-3' onClick={handleDownload} >PDF <i className='bi bi-file-pdf-fill'></i></Button>
              }
              <Button className='btn btn-primary text-white' onClick={handleOpenModalPayment} >CREATE PAYMENT <i className='bi bi-plus-circle-fill'></i></Button>

              {/* modal to add payment */}
              <Modal show={showModalPayment} onHide={handleCloseModalPayment}>
                <Modal.Header className='bg-primary text-white' closeButton>
                  <Modal.Title>Add Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSavePayment}>
                    <Form.Select
                      size='sm'
                      className='formProperty'
                      value={selectedApartmentHouse}
                      onChange={(e) => handleApartmentSelect(e.target.value)}

                    >
                      <option value="">Select Apartment</option>
                      {landlordApartment.map((landlord) => (
                        <option key={landlord.id} value={landlord.id}>
                          {landlord.name}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Select
                      size='sm'
                      className='formProperty'
                      value={selectedHouseId} // Update this to the selected house ID
                      onChange={(e) => handleHouseSelect(e.target.value)} // Update the selected house ID

                    >
                      <option value="">Select House</option>
                      {apartmentHouse.map((landlord) => (
                        <option key={landlord.house_id} value={landlord.house_id}>
                          {landlord.house} - {landlord.tenant}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Select
                      size='sm'
                      className='formProperty'
                      value={selectedMethod}
                      onChange={handlePaymentSelect}

                    >
                      <option value="" disabled>Select Payment Method</option>
                      <option value="cash">Cash</option>
                      <option value="direct-to-phone">Direct To Phone</option>
                    </Form.Select>


                    <Form.Select
                      size='sm'
                      className='formProperty'
                      value={selectedCategory}
                      onChange={handleCategorySelect}

                    >
                      <option value="" disabled>Select Category</option>
                      <option value="expense">Utility</option>
                      <option value="rent">Rent</option>
                    </Form.Select>

                    {selectedCategory === "expense" && (
                      <Form.Select
                        size='sm'
                        className='formProperty'
                        value={selectedUtility}
                        onChange={(e) => setSelectedUtility(e.target.value)}
                        style={{ marginTop: "10px" }}
                      >
                        <option value="" disabled selected>Select Utility</option>
                        {selectedUtilities.map((utility) => (
                          <option key={utility.id} value={utility.id}>
                            {utility.title}
                          </option>
                        ))}

                      </Form.Select>

                    )}

                    <Form.Select
                      size='sm'
                      className='formProperty'
                      value={invoiceSelectedId}
                      onChange={(e) => handleInvoiceSelect(e.target.value)}

                    >
                      <option value="">Month Invoice Payment</option>
                      {monthNames.map((month, index) => {
                        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
                        const hasInvoiceForMonth = invoices.some(invoice => invoice.month.toLowerCase() === month);

                        return (
                          < >
                            {hasInvoiceForMonth ? (
                              invoices.map((invoice) => (
                                invoice.month.toLowerCase() === month ? (
                                  <option
                                    key={invoice.id}
                                    value={invoice.month}
                                    style={{ color: invoice.status === "unpaid" ? 'red' : 'orange' }}
                                  >
                                    {invoice.month} - {invoice.status} - {invoice.amount}
                                  </option>
                                ) : null
                              ))
                            ) : (
                              <option key={month} value={month}>
                                {formattedMonth}
                              </option>
                            )}
                          </>
                        );
                      })}
                    </Form.Select>

                    <input
                      className="input-field"
                      type="text"
                      name="phone_number"
                      placeholder='Enter Phone Number'
                      value={formDataPayment.phone_number}
                      onChange={handleChangePayment}
                      required
                      style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                    />
                    {formDataPayment.errorMessage && (
                      <p style={{ color: 'red' }}>{formDataPayment.errorMessage}</p>
                    )}
                    <div className="input-container">
                      <div className="placeholder-text mt-3 mb-2" style={{ fontSize: '0.9rem' }}>Choose Payment Date</div>

                      <input
                        className="input-field"
                        type="date"
                        name="paid_date"
                        value={formDataPayment.paid_date}
                        onChange={handleChangePayment}
                        required
                        style={{ borderRadius: '5px', border: '1px solid lightgray', outline: '0' }}
                      />
                    </div>
                    <input
                      className="input-field"
                      type="number"
                      name="amount"
                      placeholder='Enter Amount'
                      value={formDataPayment.amount}
                      onChange={handleChangePayment}
                      required
                      style={{ borderRadius: '5px', border: '1px solid lightgray', marginTop: '1rem', outline: '0' }}
                    />
                    <Button className='loginBtn mt-3 ms-4' type='submit'>Save</Button>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleCloseModalPayment}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>
          <Table className='table'>
            {yearFilterActive ?
              <thead className='underline'>
                <th>Tenant Image</th>
                <th onClick={() => handleSort("tenant_name")}>Tenant<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("house_number")}>House<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("status")}>Status<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("date")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("paid")}>Paid<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("invoice_month")}>Invoice Month<i className='bi bi-chevron-expand'></i></th>

                {/* {uniqueMonth.map((months) => (
                  <th onClick={() => handleSort("invoice_month")}>Invoice Month{months.invoice_month}<i className='bi bi-chevron-expand'></i></th>

                ))} */}

              </thead>
              :
              <thead className='underline'>
                <th>Tenant Image</th>
                <th onClick={() => handleSort("tenant_name")}>Tenant<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("house_number")}>House<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("status")}>Status<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("date")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
                <th onClick={() => handleSort("paid")}>Paid<i className='bi bi-chevron-expand'></i></th>
              </thead>
            }

            <tbody>
              {displayedItems.map((Defaulter, index) =>
                yearFilterActive ?
                  <tr>
                    <td style={{ width: "15%" }}>
                      <Image src={Defaulter.tenant_image} style={{ width: "20%", borderRadius: "50%" }} />
                    </td>
                    <td>{Defaulter.tenant_name}</td>
                    <td>{Defaulter.house_number}</td>
                    {Defaulter.status === 'paid' ? (
                      <td><Button className={`btn btn-success btn-sm`}>{Defaulter.status}</Button></td>
                    ) :
                      Defaulter.status === 'unpaid' ?
                        (
                          <td><Button className={`btn btn-danger btn-sm`}>{Defaulter.status}</Button></td>
                        )
                        :
                        (
                          <td><Button className={`btn btn-warning text-white btn-sm`}>{Defaulter.status}</Button></td>
                        )
                    }
                    <td>{Defaulter.date}</td>
                    <td>{Defaulter.paid}</td>
                    <td className='text-uppercase'>{Defaulter.invoice_month}</td>
                  </tr>
                  :
                  <tr>
                    <td style={{ width: "15%" }}>
                      <Image src={Defaulter.tenant_image} style={{ width: "20%", borderRadius: "50%" }} />
                    </td>
                    <td>{Defaulter.tenant_name}</td>
                    <td>{Defaulter.house_number}</td>
                    {Defaulter.status === 'paid' ? (
                      <td><Button className={`btn btn-success btn-sm`}>{Defaulter.status}</Button></td>
                    ) :
                      Defaulter.status === 'unpaid' ?
                        (
                          <td><Button className={`btn btn-danger btn-sm`}>{Defaulter.status}</Button></td>
                        )
                        :
                        (
                          <td><Button className={`btn btn-warning text-white btn-sm`}>{Defaulter.status}</Button></td>
                        )
                    }

                    <td>{Defaulter.date}</td>
                    <td>{Defaulter.paid}</td>


                  </tr>

              )}

            </tbody>
          </Table>
          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            {yearFilterActive ?
              <span>{`${startIndex + 1}-${endIndex} of ${uniqueUserList.length}`}</span>

              :
              <span>{`${startIndex + 1}-${endIndex} of ${selectedHouses.length}`}</span>
            }
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>
          </div>


        </div>



      </div>
    </>
  )
}

export default RentPaymentReport