import React, { useState, useEffect } from 'react'
import './OccupancyReport.scss'
import { Button, Table, Form, FormControl, Image, Modal, Row, Col } from 'react-bootstrap';
import { saveAs } from 'file-saver';
// import { PDFExport } from '@progress/kendo-react-pdf';
import { serverUrl } from "../../../serverUrl";
import Vacant from '../../../assets/img/vacant.png'
import { toast } from 'react-toastify';
import loaderImage from '../../../assets/img/logo.png'
import { Link, useParams } from 'react-router-dom';
import Header from '../Header';
import SideBar from '../SideBar';

function OccupancyReport() {
    // getting the id and finding the apparment details
    const { id } = useParams();
      // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // houses within the apartment
  const [selectedHouses, setSelectedHouses] = useState([])
  const [occupiedCount, setOccupiedCount] = useState({});
  const [houseCount, setHouseCount] = useState({});

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  // Get the current month automatically
  const currentDate = new Date();
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // get the months till today
  const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
    const month = new Date(currentDate.getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;

  const years = Array.from({ length: 21 }, (_, i) => startYear - i);


  // display 12 churches per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);

  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };

  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const [isLoading, setIsLoading] = useState(false);

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);


  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [monthFilterActive, setMonthFilterActive] = useState("")
  const [yearFilterActive, setYearFilterActive] = useState("")


  const handleCloseModal = () => {

    setShowModal(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    if (yearFilterActive) {
      localStorage.removeItem('yearOccupancy');
    }
    localStorage.setItem("monthOccupancy", month);
    setShowModal(false);
    window.location.reload();

  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
    if (monthFilterActive) {
      localStorage.removeItem('monthOccupancy');
    }

    localStorage.setItem("yearOccupancy", year);
    setShowModal(false);
    window.location.reload();

  };
  const [rentPercentageApartment, setRentPercentageApartment] = useState('')

  // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
  useEffect(() => {

    // get if the filter is set
    const setMonthFilter = localStorage.getItem("monthOccupancy")
    setMonthFilterActive(setMonthFilter)

    // get if the filter is set for year
    const setYearFilter = localStorage.getItem("yearOccupancy")
    setYearFilterActive(setYearFilter)
    let monthSelect = "";
    let yearSelect = "";

    if (setMonthFilter) {
      monthSelect = setMonthFilter
    }
    else {
      monthSelect = currentMonth
    }

    if (setYearFilter) {
      yearSelect = setYearFilter
      const occupancyYearlyMonthly = []
      // we will have to loop through all the months but with the selected year

      // get the percentage of the apartment occupancy per year
      serverUrl.get(`/tenant/generate_yearly_apartment_occupancy/?apartment_id=${id}&year=${yearSelect}`, config)
        .then((res) => {
          setRentPercentageApartment(res.data.average_occupancy_percentage)
        })
        .catch((error) => {
          console.log(error)
        })

      Promise.all(
        months.map((month) =>
          // get the houses to be displayed
          serverUrl.get(`/landlord/react-occupancy-report/?apartment_id=${id}&month=${month.toLowerCase()}&year=${yearSelect}`, config)
            .then((res) => {
              const occupancyHouseYearlyMonthly = res.data.results


              occupancyYearlyMonthly.push(occupancyHouseYearlyMonthly)

            })
            .catch((error) => {
              console.log(error);
            })
            .then(() => {
              // Combine the arrays for the houses
              const combinedData = [].concat.apply([], occupancyYearlyMonthly);

              const vacantHouseIds = new Set();
              // Remove duplicates based on tenant data while including vacant houses
              const uniqueData = combinedData.filter((house, index, self) => {
                if (house.status === "vacant") {
                  // Include vacant houses only if their IDs are not encountered before
                  if (!vacantHouseIds.has(house.house_id)) {
                    vacantHouseIds.add(house.house_id);
                    return true;
                  }
                  return false;
                } else {
                  // Find the index of the current house based on tenant data
                  const foundIndex = self.findIndex((h) => h.tenant === house.tenant);

                  // Include the house if it's the first occurrence of the tenant or if the house status is vacant
                  return foundIndex === index || house.status === "vacant";
                }
              });

              setSelectedHouses(uniqueData)
            })
            .catch((error) => {
              console.log(error);
            })

        )
      )
    }
    else {
      // get the houses linked to the apartment
      serverUrl.get(`/landlord/react-occupancy-report/?apartment_id=${id}&month=${monthSelect}&year=${currentYear}`, config)
        .then((res) => {
          // map through the houses and get the image of each house
          setSelectedHouses(res.data.results)

        })
        .catch((error) => {
          console.log(error);

        });

      // for the occupancy percentage purpose
      serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${id}&month=${monthSelect}&year=${currentYear}`, config)
        .then((res) => {
          setRentPercentageApartment(res.data.occupancy_percentage)
        })
    }



    // get the total numbers of the houses in the apartment
    serverUrl.get(`/landlord/house/?apartment_id=${id}`, config)
      .then((res) => {
        setHouseCount(res.data.count)
      })
      .catch((error) => {
        console.log(error)
      })

  }, []);
  // searching function
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = selectedHouses.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });



  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  // exporting data in excel and in pdf
  const exportCSV = () => {
    const csvData = [
      ['Tenant', 'House', 'Status', 'Occupied Days', 'Date In', 'Date Out'],
      ...selectedHouses.map(item => [item.tenant, item.house, item.status, item.occupied_days, item.date_in, item.date_out])
    ];

    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'occupancy_report.csv');
  };

  const pdfExportRef = React.useRef();

  function handleDownload() {

    setIsLoading(true);

    serverUrl.get(`/tenant/generate_apartment_occupancy/?apartment_id=${id}&download=true`, config)
      .then((res) => {
        // create a download url for the pdf and then automatically download the pdf
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'occupancy_report.pdf');
        document.body.appendChild(link);
        link.click();
        toast.success('Successfully Downloaded Your Occupancy Report');
        setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);
      })



  };
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('monthOccupancy');
    localStorage.removeItem('yearOccupancy');
    toast.success('Successfully Reset The Occupancy Report Filter!');
    window.location.reload()
  };
   // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
       <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='OccupancyReport'>
        {/* list of the tenants of the apartment and payment details */}
        <div className='tasks ' >
          {rentPercentageApartment > 0 ?
            <>
              {((rentPercentageApartment)).toFixed(0) < 33 ? (
                <Button className='btn btn-sm bg-danger many'>{((rentPercentageApartment)).toFixed(0)} % Occupied</Button>

              ) : ((rentPercentageApartment)).toFixed(0) < 67 ? (

                <Button className='btn btn-sm bg-warning many'>{((rentPercentageApartment)).toFixed(0)} % Occupied</Button>
              ) : (
                <Button className='btn btn-sm bg-success many'>{((rentPercentageApartment)).toFixed(0)} % Occupied</Button>
              )}
            </>
            :
            <Button className='btn btn-sm bg-danger many'>0 % Occupied</Button>

          }
          <h2>occupancy report </h2>

          <div className='d-flex justify-content-between'>
            <Form.Select size='sm' className='formCal' onChange={handleSelectChange} style={{ width: "20%" }}>
              <option selected disabled>Filter Report By:</option>
              <option>Monthly</option>
              <option>Yearly</option>
            </Form.Select>

            {monthFilterActive ?
              <p className='filterText'>Occupancy Report For {monthFilterActive} </p>
              : yearFilterActive ?
                <p className='filterText'>Occupancy Report For {yearFilterActive}</p>
                :
                <p className='filterText' style={{ marginRight: "31rem" }}>Occupancy Report For {currentMonth}</p>

            }

            {monthFilterActive || yearFilterActive ?
              <Button className='text-white filterButton' onClick={handleReset}>Reset Filter</Button>

              :
              <></>}
          </div>


          {/* modal to be displayed */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='bg-primary text-white' closeButton>
              <Modal.Title>Filter By {selectedOption}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedOption === currentMonth ?
                (
                  <>
                    <p>{currentMonth}</p>

                  </>
                )
                : selectedOption === "Monthly" ?
                  (
                    <>
                      <Form>
                        <Row className="flex-wrap justify-content-between">
                          {months.map((month) => (
                            <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                              <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                                {month}
                              </Button>
                            </Col>
                          ))}
                        </Row>

                      </Form>
                    </>

                  )
                  : selectedOption === "Yearly" ?
                    (
                      <>
                        <Form>
                          <Row className="flex-wrap justify-content-between">
                            {displayedYears.map((year) => (
                              <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                                <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleYearButtonClick(year)}>
                                  {year}
                                </Button>
                              </Col>
                            ))}
                          </Row>
                          <div className="mt-3 d-flex justify-content-center">
                            {currentYearPage > 0 && (
                              <Button variant="outline-primary" onClick={handlePrevYearPage}>
                                Previous
                              </Button>
                            )}
                            {currentYearPage < totalPagesYear - 1 && (
                              <Button variant="outline-primary" onClick={handleNextYearPage}>
                                Next
                              </Button>
                            )}
                          </div>
                        </Form>
                      </>
                    ) :
                    (
                      <>
                        <p>Date</p>
                      </>
                    )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='d-flex justify-content-between'>
            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              <Button className='btn btn-danger text-white' onClick={handleDownload}>PDF <i className='bi bi-file-pdf-fill'></i></Button>
            </div>

            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>

        </div>
      </div >
    </>
  )
}

export default OccupancyReport